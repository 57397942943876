import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {
  BehaviorSubject,
  catchError,
  first,
  firstValueFrom,
  map,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { AddOn } from '../models/addOn.model';
import { MemberType, Plan } from '../models/plan.model';
import {
  Member,
  Policy,
  PolicyAddOn,
  ProductPaymentStatus,
  PolicyStatus,
  DebitOrder,
} from '../models/policy.model';
import { PlanService } from './plan.service';
import { SnackBarService } from './snack-bar.service';
import { uuidv4 } from '@firebase/util';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { DateTimeService } from './date-time.service';
import { FilterService } from './filter.service';
import { Router } from '@angular/router';
import { MainService } from './main.service';
import { AddOnService } from './add-on.service';
import { PolicyLogService } from './policy-log.service';
import axios from 'axios';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  selectedPolicy: Policy | undefined;
  selectedPolicyMember: Member | undefined;
  selectedPolicyAddOn: PolicyAddOn | undefined;
  selectedPolicyMemberIndex: number | undefined;
  selectedPolicyAddOnIndex: number | undefined;
  loadedPolicies: Policy[] = [];
  plans: Plan[] | undefined;
  addOns: AddOn[] | undefined;
  allowedMemberTypes: MemberType[] | undefined;
  lastPolicyNumber: string | undefined = undefined;
  returnRoute: string | undefined;
  userPolicies: Policy[] = [];

  policySubscription: Subscription | undefined;

  dataSourcePolicies: MatTableDataSource<any> | undefined;
  dataSourceAddOns: MatTableDataSource<any> | undefined;
  dataSourcePolicyMembers: MatTableDataSource<any> | undefined;
  dataSourceTransactions: MatTableDataSource<any> | undefined;

  addPlanClicked = false;
  addTopUpClicked = false;
  addTombstoneClicked = false;

  // Form state
  loading = false;
  success = false;
  policyFilterClicked = true;

  payAtPrefix = '11701';

  // BehaviorSubject to hold a boolean based on if policiesSubject recently updated
  private updated$ = new BehaviorSubject<boolean>(false);
  // BehaviorSubject to hold the policies data
  private policiesSubject = new BehaviorSubject<Policy[]>([]);
  public policies$ = this.policiesSubject.asObservable();
  private offlineTransactionPoliciesSubject = new BehaviorSubject<Policy[]>([]);
  public offlineTransactionPoliciesSubject$ =
    this.policiesSubject.asObservable();

  destroy$ = new Subject<void>();
  destroySelectedPolicy$ = new Subject<void>();

  policyDoc: AngularFirestoreDocument<Policy> | undefined;
  selectedPolicy$: Observable<Policy | undefined> | undefined;

  unsubscribeFromSnapshot: (() => void) | undefined;

  @Output() loadedPoliciesUpdated = new EventEmitter<void>();
  @Output() loadedOfflineTransactionPoliciesUpdated = new EventEmitter<void>();
  @Output() selectedPolicyUpdated = new EventEmitter<void>();
  @Output() selectedPolicyChanged = new EventEmitter<void>();
  @Output() selectedPolicyCommentsChanged = new EventEmitter<void>();
  @Output() selectedPolicyUnallocatedBalanceUpdated = new EventEmitter<void>();
  @Output() selectedPolicyProductPaymentStatusUpdated =
    new EventEmitter<void>();
  @Output() selectedPolicyIntendedPaymentDayUpdated = new EventEmitter<void>();

  constructor(
    private db: AngularFirestore,
    private planService: PlanService,
    private addOnService: AddOnService,
    public dateTimeService: DateTimeService,
    private policyLogService: PolicyLogService,
    private userService: UserService,
    private filterService: FilterService,
    public snackBarService: SnackBarService,
    private router: Router,
    private mainService: MainService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());

    this.userService.routeUserAppropriatelyRequested
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.routeUserAppropriately();
      });
  }

  //Initial Load {
  async ngOnInit() {
    this.updateBoolean(false);
    // Get all plans from the plan service
    this.plans = this.planService.allPlans;
  }

  async setSelectedPolicy(docId?: string) {
    try {
      if (!docId) {
        // this.router.navigate(['/policies']);
        throw new Error('DOCUMENT ID NOT PROVIDED!');
      }

      sessionStorage.setItem('selectedPolicyId', docId);
      this.selectedPolicyChanged.emit();

      if (!this.planService.allPlans) {
        await this.planService.loadPlans();
      }

      if (!this.addOnService.allAddOns) {
        await this.addOnService.loadAddOns();
      }

      this.policyDoc = this.db.collection<Policy>('policy').doc(docId);

      this.selectedPolicy$ = this.policyDoc.snapshotChanges().pipe(
        map((action) => {
          if (action.payload.exists) {
            return {
              id: action.payload.id,
              ...(action.payload.data() as Policy),
            };
          } else {
            this.snackBarService.openRedSnackBar('POLICY DOCUMENT NOT FOUND!');
            throw new Error('POLICY DOCUMENT NOT FOUND!');
          }
        }),
        catchError((err) => {
          this.handleError(err);
          throw err;
        })
      );

      await new Promise<void>((resolve, reject) => {
        this.selectedPolicy$
          ?.pipe(takeUntil(this.destroy$ || this.destroySelectedPolicy$))
          .subscribe({
            next: (policy) => {
              const storedPolicyId = sessionStorage.getItem('selectedPolicyId');
              if (policy && policy.id === storedPolicyId) {
                this.selectedPolicy = policy;
                this.refreshPolicyAddOnDataSource();
                this.refreshPolicyMemberDataSource();
                this.selectedPolicyUpdated.emit();
                resolve();
              }
            },
            error: (err) => {
              reject(err);
            },
          });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.handleError(err);
        throw err;
      }
    }
  }

  private handleError(err: Error) {
    if (err.message) this.snackBarService.latestError = err.message;

    this.mainService.setLoading(false);
    this.snackBarService.openRedSnackBar('SETTING THE SELECTED POLICY FAILED!');
    this.router.navigate(['/policies']);
  }

  // this.userPolicies
  async getPoliciesForUser(): Promise<void> {
    try {
      const cellNum = this.userService.getAuth().currentUser?.phoneNumber;
      if (!cellNum) {
        throw new Error('Phone number is not available.');
      }

      const uid = this.userService.getAuth().currentUser?.uid;
      if (!uid) {
        throw new Error('User ID is not available.');
      }

      const querySnapshot = await firstValueFrom(
        this.db
          .collection<Policy>('policy', (ref) =>
            ref.where(
              'memberIdAndCellNumbers',
              'array-contains',
              `${uid}${cellNum}`
            )
          )
          .get()
      );

      this.userPolicies = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as Policy;
      });

      this.userPolicies.sort((a, b) => {
        const isPrimaryA = this.policyHasPrimaryMember(a);
        const isPrimaryB = this.policyHasPrimaryMember(b);
        const isInactiveA = a.status === 'INACTIVE';
        const isInactiveB = b.status === 'INACTIVE';

        // Sort by inactive status first to ensure inactive policies come last
        if (!isInactiveA && isInactiveB) return -1;
        if (isInactiveA && !isInactiveB) return 1;

        // Then sort by primary member
        if (isPrimaryA && !isPrimaryB) return -1;
        if (!isPrimaryA && isPrimaryB) return 1;

        return 0; // If both are equal, maintain their relative order
      });
    } catch (error) {
      console.error('Error fetching policies:', error);
    }
  }

  policyHasPrimaryMember(policy: Policy): boolean {
    if (!policy.members || !policy.planId) {
      return false;
    }

    return policy.members.some((member) =>
      this.isPrimaryMember(member, policy.planId ?? '')
    );
  }

  async routeUserAppropriately() {
    const currentUserUid = this.userService.getAuth().currentUser?.uid;

    if (!currentUserUid) {
      console.error('No current user UID found.');
      await this.router.navigate(['/login']);
      return;
    }

    if (!this.planService.allPlans || this.planService.allPlans.length === 0) {
      try {
        await this.planService.loadPlans();
      } catch (error) {
        console.error('Error loading plans:', error);
        await this.router.navigate(['/login']);
        return;
      }
    }

    if (!this.userPolicies || this.userPolicies.length === 0) {
      try {
        await this.getPoliciesForUser();
      } catch (error) {
        console.error('Error loading user policies:', error);
        await this.router.navigate(['/login']);
        return;
      }
    }

    let primaryMember;
    let policyFound = false;

    for (const policy of this.userPolicies) {
      primaryMember = this.getPolicyPrimaryMember(policy);

      if (primaryMember?.idNumber === currentUserUid) {
        if (policy.status === PolicyStatus.IN_PROGRESS) {
          await this.router.navigate(['/policy-member-details', policy.id]);
        } else if (this.userPolicies.length > 1) {
          await this.router.navigate(['/select-policy']);
        } else {
          await this.router.navigate(['/policy-details', policy.id]);
        }
        policyFound = true;
        break;
      }
    }

    if (!policyFound) {
      await this.router.navigate(['/policy-member-details', 'new']);
    }
  }

  // Get the Primary member of the policy with the given planId
  getPolicyPrimaryMember(policy: Policy) {
    const policyMembers = policy.members;
    const planId = policy.planId;
    if (policyMembers && planId) {
      return policyMembers.find(
        (member) =>
          member.memberTypeId ===
            this.planService.getPlanPrimaryMemberType(planId)?.id &&
          (member.status === 'ACTIVE' || member.status === 'REQUESTED')
      );
    } else {
      return undefined;
    }
  }

  // Returns the name and class of the member type with the given ID.
  getMemberTypeNameAndClassById(memberTypeId: string) {
    if (memberTypeId) {
      if (this.planService.allPlans)
        for (const plan of this.planService.allPlans) {
          const memberType = plan.memberType?.find(
            (type) => type.id === memberTypeId
          );
          if (memberType) {
            return {
              text: memberType.name,
              class:
                plan.id !== this.selectedPolicy?.planId
                  ? ['warningColor']
                  : [''],
            };
          }
        }
    }
    return { text: '(NOT FOUND)', class: ['italic'] };
  }

  getMemberByIdNumberAndPolicy(memberIdNumber: string, policy: Policy) {
    if (policy.members) {
      let member = policy.members.find(
        (member) => member.idNumber === memberIdNumber
      );
      return member;
    }
    return undefined;
  }

  isPrimaryMember(policyMember: Member, planId: string) {
    if (policyMember?.memberTypeId) {
      return (
        this.planService.getPlanPrimaryMemberType(planId)?.id ===
        policyMember.memberTypeId
      );
    }
    return false;
  }
  // Check if the given member type can be added to the current policy
  canAddMemberTypeToPolicy(memberTypeId: string): boolean {
    if (this.selectedPolicy?.planId && this.selectedPolicy?.members) {
      const memberType = this.planService.getMemberTypeById(memberTypeId);
      if (!memberType?.maxAllowed || memberType.status === 'INACTIVE') {
        return false;
      }
      const activeMembers = this.selectedPolicy.members.filter(
        (member) =>
          member.memberTypeId === memberTypeId &&
          member.status != 'INACTIVE' &&
          member.status != 'CLAIMED'
      );
      return activeMembers.length < memberType.maxAllowed;
    }
    return true;
  }

  async validateIdNumberDuplicate(formValues: any, planId: string) {
    const response = await axios.post<{ valid: boolean; message: string }>(
      environment.isIdNumberDuplicateURL,
      { formValues, planId, validPortalUser: environment.validPortalUser }
    );
    return response.data;
  }

  getAllowedAddOns(currentAddOnId?: string): AddOn[] {
    if (!this.selectedPolicy?.planId) {
      return [];
    }

    const addOns = this.addOnService.allAddOns;

    const allowedAddOns = addOns?.filter((addOn) => {
      return (
        addOn.id === currentAddOnId ||
        (addOn.status !== 'INACTIVE' &&
          addOn.plans?.some(
            (addOnPlan) =>
              addOnPlan.planId === this.selectedPolicy?.planId &&
              addOnPlan.status !== 'INACTIVE'
          ) &&
          !this.selectedPolicy?.addOns?.some(
            (policyAddOn) =>
              policyAddOn.addOnId === addOn.id &&
              policyAddOn.status !== 'INACTIVE'
          ))
      );
    });
    return allowedAddOns || [];
  }

  // Get all allowed member types for the current policy
  refreshAllowedMemberTypes(currentMemberTypeId?: string) {
    if (!this.selectedPolicy?.planId) {
      this.allowedMemberTypes = [];
      return;
    }

    const planMemberTypes = this.planService.getPlanMemberTypes(
      this.selectedPolicy.planId
    );
    if (!planMemberTypes) {
      this.allowedMemberTypes = [];
      return;
    }

    const allowedMemberTypes = planMemberTypes.filter((memberType) => {
      return (
        memberType.id &&
        (this.canAddMemberTypeToPolicy(memberType.id) ||
          memberType.id === currentMemberTypeId)
      );
    });

    this.allowedMemberTypes = allowedMemberTypes;
  }

  getAppropriatePolicyStatus(status: string): {
    text: string;
    styleClass: string;
  } {
    const defaultResult = { text: '', styleClass: '' };

    switch (status) {
      case PolicyStatus.ACTIVE:
        return { text: 'ACTIVE', styleClass: 'activeColor' };

      case PolicyStatus.INACTIVE:
        return { text: 'INACTIVE', styleClass: 'warningColor' };

      case PolicyStatus.LAPSED:
        return { text: 'LAPSED', styleClass: 'warningColor' };

      case PolicyStatus.PENDING:
        return { text: 'PENDING', styleClass: 'waitingColor' };

      case PolicyStatus.ARREARS:
        return { text: 'ARREARS', styleClass: 'waitingColor' };

      case PolicyStatus.IN_PROGRESS:
        return { text: 'IN PROGRESS', styleClass: 'inProgressColor' };

      case PolicyStatus.UNCONFIRMED:
        return { text: 'UNCONFIRMED', styleClass: 'requestedColor' };
      default:
        return defaultResult;
    }
  }

  getAppropriatePolicyDebitOrderStatus(): {
    text: string;
    styleClass: string;
  } {
    const defaultResult = { text: '', styleClass: '' };

    switch (this.selectedPolicy?.debitOrder?.status) {
      case 'ACTIVE':
        return { text: 'ACTIVE', styleClass: 'activeColor' };

      case 'INACTIVE':
        return { text: 'INACTIVE', styleClass: 'warningColor' };

      default:
        return defaultResult;
    }
  }

  getAppropriateMemberStatus(member: any | undefined, statusOnly = false) {
    if (
      (member?.status === 'ACTIVE' || member?.memberStatus === 'ACTIVE') &&
      member?.memberTypeId
    ) {
      const waitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;
      if (waitingPeriod !== undefined) {
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        const waitingPeriodLeft = Number(
          waitingPeriod -
            this.dateTimeService.getDaysDifference(
              this.dateTimeService.dateToTimestamp(now) ?? Timestamp.now(),
              this.dateTimeService.verifyTimestamp(member?.waitingDate)
            )
        );
        if (waitingPeriodLeft <= 0) {
          return { text: 'ACTIVE', class: ['activeColor clickable'] };
        } else if (
          waitingPeriodLeft > 0 &&
          member.waitingDate?.seconds != member?.inceptionDate?.seconds
        ) {
          return {
            text: waitingPeriodLeft.toString() + ' DAYS',
            class: ['requestedColor'],
          };
        } else {
          return {
            text: waitingPeriodLeft.toString() + ' DAYS',
            class: ['waitingColor'],
          };
        }
      }
    } else if (
      member?.status === 'REQUESTED' ||
      member?.memberStatus === 'REQUESTED'
    ) {
      if (statusOnly) {
        return {
          text: 'REQUESTED',
          class: ['requestedColor'],
        };
      }
      let newWaitingPeriod = 0;
      if (this.selectedPolicy?.planId && member?.memberTypeId) {
        let waitingPeriod = this.planService.getMemberTypeById(
          member.memberTypeId
        ).waitingPeriod;
        if (waitingPeriod)
          waitingPeriod =
            waitingPeriod -
            Number(
              this.dateTimeService.getDaysDifference(
                member.inceptionDate,
                member.waitingDate
              )
            );
        newWaitingPeriod = waitingPeriod ?? 0;
        if (waitingPeriod) {
          newWaitingPeriod =
            waitingPeriod -
            Number(
              this.dateTimeService.getDaysDifference(
                member.waitingDate,
                member.requestedWaitingDate
              )
            );
          if (newWaitingPeriod <= 0) {
            return {
              text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
              class: ['requestedColor'],
              newWaitingPeriod,
            };
          } else if (newWaitingPeriod > 0) {
            return {
              text:
                'REQUESTED ' +
                waitingPeriod +
                ' TO ' +
                newWaitingPeriod +
                ' DAYS',
              class: ['requestedColor'],
              newWaitingPeriod,
            };
          }
        }
      }
      return {
        text: '(NOT FOUND)',
        class: ['whiteColor italic'],
        newWaitingPeriod,
      };
    } else if (
      member?.status === 'CLAIMED' ||
      member?.memberStatus === 'CLAIMED'
    ) {
      return { text: 'CLAIMED', class: ['whiteColor'] };
    } else if (
      member?.status === 'INACTIVE' ||
      member?.memberStatus === 'INACTIVE'
    ) {
      return { text: 'INACTIVE', class: ['warningColor'] };
    }
    return {
      text: '',
      class: ['whiteColor'],
    };
  }

  getPolicyActiveProductsString(policy: Policy) {
    let activeProducts: string[] = [];
    if (
      policy.status != PolicyStatus.INACTIVE &&
      policy.status != PolicyStatus.INACTIVE &&
      policy.planId
    ) {
      const planName = this.planService.getPlanById(policy.planId)?.name;
      if (planName) activeProducts.push(planName);
    }

    policy.addOns?.forEach((policyAddOn) => {
      if (
        policyAddOn.status != 'INACTIVE' &&
        policyAddOn.status != 'CLAIMED' &&
        policyAddOn.addOnId
      ) {
        const addOnName = this.addOnService.getAddOnById(
          policyAddOn.addOnId
        )?.name;
        if (addOnName) activeProducts.push(addOnName);
      }
    });
    return activeProducts.join(' + ');
  }

  getPolicyMemberWaitingPeriodLeft(
    member: Member,
    timestamp?: Timestamp,
    policyData?: Policy
  ) {
    const policy = this.selectedPolicy ?? policyData;
    if (policy?.planId && member?.memberTypeId) {
      const waitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;

      if (waitingPeriod)
        return Number(
          waitingPeriod -
            this.dateTimeService.getDaysDifference(
              timestamp ?? Timestamp.now(),
              this.dateTimeService.verifyTimestamp(member.waitingDate)
            )
        );
    }
    return undefined;
  }

  getPolicyAddOnWaitingPeriodLeft(
    policyAddOn: PolicyAddOn,
    timestamp?: Timestamp,
    policyData?: Policy
  ) {
    const policy = policyData ?? this.selectedPolicy;
    if (policy?.planId && policyAddOn?.addOnId) {
      const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
      const waitingPeriod = addOn?.plans?.find(
        (addOnPlan) => addOnPlan.planId === policy?.planId
      )?.waitingPeriod;
      if (waitingPeriod) {
        return Number(
          waitingPeriod -
            this.dateTimeService.getDaysDifference(
              timestamp ?? Timestamp.now(),
              policyAddOn.status !== 'REQUESTED'
                ? this.dateTimeService.verifyTimestamp(policyAddOn.waitingDate)
                : this.dateTimeService.verifyTimestamp(
                    policyAddOn.inceptionDate
                  )
            )
        );
      }
    }
    return undefined;
  }

  getAppropriateAddOnStatus(
    policyAddOn: PolicyAddOn,
    statusOnly = false,
    policyData?: Policy
  ) {
    const policy = this.selectedPolicy ?? policyData;
    if (policy?.planId) {
      if (policyAddOn?.status === 'ACTIVE' && policyAddOn?.addOnId) {
        const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
        const waitingPeriod = addOn?.plans?.find(
          (addOnPlan) => addOnPlan.planId === policy?.planId
        )?.waitingPeriod;
        if (waitingPeriod !== undefined) {
          let now = new Date();
          now.setHours(0, 0, 0, 0);
          const waitingPeriodLeft = Number(
            waitingPeriod -
              this.dateTimeService.getDaysDifference(
                this.dateTimeService.dateToTimestamp(now) ?? Timestamp.now(),
                this.dateTimeService.verifyTimestamp(policyAddOn?.waitingDate)
              )
          );
          if (waitingPeriodLeft <= 0) {
            return { text: 'ACTIVE', class: ['activeColor clickable'] };
          } else if (
            waitingPeriodLeft > 0 &&
            policyAddOn.waitingDate?.seconds !=
              policyAddOn?.inceptionDate?.seconds
          ) {
            return {
              text: waitingPeriodLeft.toString() + ' DAYS',
              class: ['requestedColor'],
            };
          } else {
            return {
              text: waitingPeriodLeft.toString() + ' DAYS',
              class: ['waitingColor'],
            };
          }
        }
      } else if (policyAddOn?.status === 'REQUESTED') {
        if (statusOnly) {
          return {
            text: 'REQUESTED',
            class: ['requestedColor'],
          };
        }
        let newWaitingPeriod = 0;
        if (policyAddOn?.addOnId) {
          const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
          let waitingPeriod = addOn?.plans?.find(
            (addOnPlan) => addOnPlan.planId === policy?.planId
          )?.waitingPeriod;

          if (waitingPeriod && policyAddOn.inceptionDate)
            waitingPeriod =
              waitingPeriod -
              Number(
                this.dateTimeService.getDaysDifference(
                  policyAddOn.inceptionDate,
                  policyAddOn.waitingDate
                )
              );
          newWaitingPeriod = waitingPeriod ?? 0;
          if (waitingPeriod && policyAddOn.waitingDate) {
            newWaitingPeriod =
              waitingPeriod -
              Number(
                this.dateTimeService.getDaysDifference(
                  policyAddOn.waitingDate,
                  policyAddOn.requestedWaitingDate
                )
              );
            if (newWaitingPeriod <= 0) {
              return {
                text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
                class: ['requestedColor'],
                newWaitingPeriod: 0,
              };
            } else if (newWaitingPeriod > 0) {
              return {
                text:
                  'REQUESTED ' +
                  waitingPeriod +
                  ' TO ' +
                  newWaitingPeriod +
                  ' DAYS',
                class: ['requestedColor'],
                newWaitingPeriod,
              };
            }
          }
        }
        return {
          text: '(NOT FOUND)',
          class: ['whiteColor italic'],
          newWaitingPeriod,
        };
      } else if (policyAddOn?.status === 'INACTIVE') {
        return { text: 'INACTIVE', class: ['warningColor'] };
      }
    }
    return {
      text: '(NOT FOUND)',
      class: ['whiteColor italic'],
    };
  }

  isProductActive(id?: string): boolean {
    if (!id) return false;

    if (id === this.selectedPolicy?.planId) {
      return this.selectedPolicy.status === 'INACTIVE' ||
        this.selectedPolicy.status === 'LAPSED'
        ? false
        : true;
    } else {
      const addOnStatus = this.selectedPolicy?.addOns?.find(
        (policyAddOn) => policyAddOn.addOnId === id
      )?.status;
      return (addOnStatus && addOnStatus === 'ACTIVE') ||
        addOnStatus === 'REQUESTED'
        ? true
        : false;
    }
  }

  getMemberOldAndNewWaitingPeriod(member: Member) {
    let newWaitingPeriod = 0;
    if (
      this.selectedPolicy &&
      this.selectedPolicy.planId &&
      member?.memberTypeId
    ) {
      const waitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;
      newWaitingPeriod = waitingPeriod ?? 0;
      if (waitingPeriod && member.inceptionDate) {
        newWaitingPeriod =
          waitingPeriod -
          Number(
            this.dateTimeService.getDaysDifference(
              member.inceptionDate,
              member?.waitingDate
            )
          );

        if (newWaitingPeriod <= 0) {
          return {
            text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod,
          };
        } else if (newWaitingPeriod > 0) {
          return {
            text:
              'REQUESTED ' +
              waitingPeriod +
              ' TO ' +
              newWaitingPeriod +
              ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod,
          };
        }
      }
    }
    return {
      text: '(NOT FOUND)',
      class: ['whiteColor italic'],
      newWaitingPeriod,
    };
  }

  getAddOnOldAndNewWaitingPeriod(policyAddOn: PolicyAddOn) {
    let newWaitingPeriod = 0;
    if (policyAddOn?.addOnId) {
      const addOn = this.addOnService.getAddOnById(policyAddOn?.addOnId);
      const waitingPeriod = addOn?.plans?.find(
        (addOnPlan) => addOnPlan.planId === this.selectedPolicy?.planId
      )?.waitingPeriod;
      newWaitingPeriod = waitingPeriod ?? 0;
      if (
        waitingPeriod &&
        this.selectedPolicy?.addOns &&
        policyAddOn.inceptionDate
      ) {
        newWaitingPeriod =
          waitingPeriod -
          Number(
            this.dateTimeService.getDaysDifference(
              policyAddOn.inceptionDate,
              policyAddOn?.waitingDate
            )
          );
        if (newWaitingPeriod <= 0) {
          return {
            text: 'REQUESTED ' + waitingPeriod + ' TO ' + 0 + ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod: 0,
          };
        } else if (newWaitingPeriod > 0) {
          return {
            text:
              'REQUESTED ' +
              waitingPeriod +
              ' TO ' +
              newWaitingPeriod +
              ' DAYS',
            class: ['requestedColor'],
            newWaitingPeriod,
          };
        }
      }
    }
    return {
      text: '(NOT FOUND)',
      class: ['whiteColor italic'],
      newWaitingPeriod,
    };
  }

  getPolicyByPolicyNumber(policyNumber: string): Promise<Policy> {
    return new Promise((resolve, reject) => {
      try {
        this.db
          .collection('policy', (ref) =>
            ref.where('policyNumber', '==', policyNumber)
          )
          .snapshotChanges()
          .pipe(first())
          .subscribe({
            next: (docSnapshots) => {
              if (docSnapshots.length > 0) {
                const id = docSnapshots[0].payload.doc.id;
                const data = docSnapshots[0].payload.doc.data() as Policy;
                data.id = id;
                resolve(data);
              } else {
                reject(new Error('No document found'));
              }
            },
            error: (err) => reject(err),
          });
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar(
            'GETTING POLICY BY POLICY NUMBER FAILED!'
          );
        }
        reject(err);
      }
    });
  }

  async getPolicyById(id: string): Promise<Policy> {
    try {
      const snapshot = await firstValueFrom(
        this.db.collection('policy').doc(id).snapshotChanges()
      );
      return {
        ...(snapshot.payload.data() as Policy),
        id: snapshot.payload.id,
      } as Policy;
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'GETTING POLICY NUMBER BY ID FAILED!'
        );
      }
      throw err;
    }
  }

  calculateAddOnWaitingDate(waitingPeriod: string, element: any) {
    if (this.selectedPolicy && this.selectedPolicy.planId && element?.addOnId) {
      const addOn = this.addOnService.getAddOnById(element.addOnId);
      const currentWaitingPeriod = addOn?.plans?.find(
        (addOnPlan) => addOnPlan.planId === this.selectedPolicy?.planId
      )?.waitingPeriod;

      const inceptionDate = this.dateTimeService.timestampToDate(
        element.inceptionDate
      );
      let waitingDate = inceptionDate;

      if (currentWaitingPeriod && inceptionDate && waitingDate) {
        waitingDate.setDate(
          inceptionDate.getDate() -
            (currentWaitingPeriod - Number(waitingPeriod))
        );
      }
      if (waitingDate) return this.dateTimeService.dateToTimestamp(waitingDate);
    }
    return undefined;
  }

  calculateMemberWaitingDate(waitingPeriod: string, member: any) {
    if (
      this.selectedPolicy &&
      this.selectedPolicy.planId &&
      member?.memberTypeId
    ) {
      const currentWaitingPeriod = this.planService.getMemberTypeById(
        member.memberTypeId
      )?.waitingPeriod;
      const inceptionDate = this.dateTimeService.timestampToDate(
        member.inceptionDate
      );
      let waitingDate = inceptionDate;

      if (currentWaitingPeriod && inceptionDate && waitingDate) {
        waitingDate.setDate(
          inceptionDate.getDate() -
            (currentWaitingPeriod - Number(waitingPeriod))
        );
      }
      if (waitingDate) return this.dateTimeService.dateToTimestamp(waitingDate);
    }
    return undefined;
  }

  getPolicyPrimaryMemberCellNum() {
    if (this.selectedPolicy) {
      let primaryMember = this.getPolicyPrimaryMember(this.selectedPolicy);
      if (
        primaryMember?.status != 'INACTIVE' &&
        primaryMember?.status != 'CLAIMED'
      ) {
        const cellNum = primaryMember?.cellNumber;
        return '+27' + cellNum?.substring(1);
      }
    }
    return '';
  }

  generatePayAtNumber(): string {
    const date = new Date();
    const year = date.getFullYear().toString().substring(2); // Last 2 digits of year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month (0-11 so +1)
    const day = date.getDate().toString().padStart(2, '0'); // Day of month
    const hours = date.getHours().toString().padStart(2, '0'); // Hours
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Seconds
    const tenthsOfSecond = Math.floor(date.getMilliseconds() / 100).toString();
    const random = this.generateRandomNumber(0, 9);

    return `${year}${month}${day}${hours}${minutes}${seconds}${tenthsOfSecond}${random}`;
  }

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async getNextPortalPolicyNumber(): Promise<string> {
    const docRef = this.db.firestore.doc('metaData/portalPolicyCounter');
    try {
      return this.db.firestore.runTransaction(
        async (transaction: firebase.firestore.Transaction) => {
          const doc = await transaction.get(docRef);
          const docData = doc.data() || {};
          const currentNumberData = docData['count'] || 0;
          const currentNumber = (currentNumberData || 0) + 1;

          transaction.update(docRef, {
            ['count']: currentNumber,
          });

          return `WON${currentNumber.toString().padStart(6, '0')}`;
        }
      );
    } catch (error) {
      this.snackBarService.openRedSnackBar(
        'ERROR GETTING NEXT ONLINE POLICY NUMBER'
      );
      throw error;
    }
  }

  // Update an existing policy with the given form values and policy document
  async updatePolicy(formValues: any) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    try {
      if (this.selectedPolicy?.id) {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
        };
        const updatedOn = Timestamp.now();

        let intendedPaymentDay = this.dateTimeService
          .timestampToDate(formValues?.inceptionDate)
          ?.getDate();

        const currentPolicy = await this.getPolicyById(this.selectedPolicy.id);

        let updateValues = {
          ...this.selectedPolicy,
          ...formValues,
          updatedBy,
          updatedOn,
        };
        if (
          !currentPolicy?.productsPaymentStatus?.find(
            (status) => status.id === this.selectedPolicy?.planId
          ) &&
          this.selectedPolicy.inceptionDate?.seconds !==
            updateValues?.inceptionDate?.seconds
        ) {
          updateValues.intendedPaymentDay = intendedPaymentDay;
        }
        await this.db
          .collection('policy')
          .doc(this.selectedPolicy.id)
          .update(updateValues);

        this.policyLogService.indexObject = 'policy';
        this.policyLogService.updateLog(oldDoc, this.selectedPolicy);
      }

      this.loading = false;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('POLICY UPDATE FAILED!');
    }
  }

  // Update the add-ons of the given policy document with the given form values and index
  async updatePolicyAddOns(
    policyDoc: Policy,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
    let newDoc = JSON.parse(JSON.stringify(policyDoc));
    try {
      if (i === -1) {
        const id = uuidv4();
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          cellNumber: this.userService.userData?.cellNumber,
        };
        const createdOn = Timestamp.now();
        const adjustedDate = new Date(
          formValues.inceptionDate.toDate().getTime()
        );
        adjustedDate.setHours(0, 0, 0, 0);

        formValues.inceptionDate =
          this.dateTimeService.dateToTimestamp(adjustedDate);
        const waitingDate = formValues.inceptionDate;
        // Add the new add-on with the modified form values, created by and created on properties
        policyDoc.addOns?.push({
          ...formValues,
          id,
          waitingDate,
          createdBy,
          createdOn,
        });
      } else if (remove) {
        // Remove the add-on at the given index from the add-ons array
        policyDoc.addOns?.splice(i, 1);
        this.success = true;
      } else if (formValues.status == 'REQUESTED') {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
        };
        const updatedOn = Timestamp.now();
        // Update the add-on at the given index with the modified form values, updated by and updated on properties
        policyDoc.addOns![i] = {
          ...policyDoc.addOns![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      } else {
        const waitingDate = this.dateTimeService.verifyWaitingDate(
          policyDoc.addOns![i].waitingDate,
          formValues.inceptionDate,
          this.dateTimeService.verifyTimestamp(oldDoc?.addOns![i].inceptionDate)
        );

        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
        };
        const updatedOn = Timestamp.now();
        policyDoc.addOns![i] = {
          ...policyDoc.addOns![i],
          ...formValues,
          waitingDate,
          updatedBy,
          updatedOn,
        };
      }

      // Code for updating the database
      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
      };
      const updatedOn = Timestamp.now();
      newDoc = JSON.parse(JSON.stringify(policyDoc));
      policyDoc.addOns = this.sortPolicyAddOns(policyDoc.addOns || []);

      let updateValues: any = {
        addOns: policyDoc.addOns,
        updatedBy,
        updatedOn,
      };

      await this.db.collection('policy').doc(policyDoc.id).update(updateValues);
      this.success = true;
    } catch (err) {
      // Error handling
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('POLICY ADD-ON UPDATE FAILED!');
    }

    // Final steps: updating the selected policy and logging
    if (policyDoc.id) await this.setSelectedPolicy(policyDoc.id);
    this.policyLogService.indexObject = 'addOn';
    this.policyLogService.updateLog(oldDoc, newDoc);
    this.loading = false;
  }

  // Update the members of the given policy document with the given form values and index
  async updatePolicyMembers(
    policyDoc: Policy,
    formValues: any,
    i: number,
    remove?: boolean
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.loading = true;
      const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));
      let newDoc = JSON.parse(JSON.stringify(policyDoc));

      try {
        if (i === -1) {
          const id = uuidv4();
          const createdBy = {
            uid: this.userService.userData?.uid,
            displayName: this.userService.userData?.displayName,
            email: this.userService.userData?.email,
            cellNumber: this.userService.userData?.cellNumber,
          };
          const createdOn = Timestamp.now();
          const inceptionDate =
            typeof formValues.inceptionDate === 'string' &&
            !isNaN(Date.parse(formValues.inceptionDate))
              ? new Date(formValues.inceptionDate)
              : formValues.inceptionDate instanceof Date
              ? formValues.inceptionDate
              : formValues.inceptionDate.toDate();

          const adjustedDate = new Date(inceptionDate.getTime());
          adjustedDate.setHours(0, 0, 0, 0);

          formValues.inceptionDate =
            this.dateTimeService.dateToTimestamp(adjustedDate);
          const waitingDate = formValues.inceptionDate;
          if (formValues.status !== 'REQUESTED') formValues.status = 'ACTIVE';

          policyDoc.members?.push({
            ...formValues,
            id,
            createdBy,
            createdOn,
            waitingDate,
          });

          if (this.isPrimaryMember(formValues, policyDoc.planId ?? ''))
            this.snackBarService.dismissSnackBar();
        } else if (remove) {
          policyDoc.members?.splice(i, 1);
          this.success = true;
        } else {
          let waitingDate;
          if (policyDoc.members)
            waitingDate = this.dateTimeService.verifyWaitingDate(
              policyDoc.members[i].waitingDate,
              formValues.inceptionDate,
              this.dateTimeService.verifyTimestamp(
                oldDoc.members[i].inceptionDate
              )
            );

          const updatedBy = {
            uid: this.userService.userData?.uid,
            displayName: this.userService.userData?.displayName,
            email: this.userService.userData?.email,
          };
          const updatedOn = Timestamp.now();

          policyDoc.members![i] = {
            ...policyDoc.members![i],
            ...formValues,
            waitingDate,
            updatedBy,
            updatedOn,
          };
        }

        const updatedMember = policyDoc.members![i];
        if (
          this.isPrimaryMember(updatedMember, policyDoc.planId ?? '') &&
          (updatedMember?.status === 'INACTIVE' ||
            updatedMember?.status === 'CLAIMED') &&
          updatedMember.memberTypeId
        ) {
          this.snackBarService.openPrimaryMemberSnackBar(
            'PLEASE SELECT A NEW ' +
              this.getMemberTypeNameAndClassById(updatedMember.memberTypeId)
                .text +
              '!'
          );
        }

        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          cellNumber: this.userService.userData?.cellNumber,
        };
        const updatedOn = Timestamp.now();

        newDoc = JSON.parse(JSON.stringify(policyDoc));
        policyDoc.members = this.sortMembers(policyDoc.members || []);

        const memberIdNumbers = (policyDoc.members || []).map(
          (member) => member.idNumber
        );
        const memberIdAndCellNumbers = (policyDoc.members || []).map(
          (member) =>
            `${member.idNumber}${this.userService.toInternationalFormat(
              member.cellNumber ?? ''
            )}`
        );

        let updateValues: any = {
          members: policyDoc.members,
          updatedBy,
          updatedOn,
          memberIdNumbers,
          memberIdAndCellNumbers,
        };

        updateValues.hasRequest = false;

        await this.db
          .collection('policy')
          .doc(policyDoc.id)
          .update(updateValues);
        this.refreshAllowedMemberTypes();
        this.success = true;
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
          console.error(err.message);
        }
        this.snackBarService.openRedSnackBar('POLICY MEMBER UPDATE FAILED!');
        reject(err);
      }
      if (policyDoc.id) await this.setSelectedPolicy(policyDoc.id);
      this.policyLogService.indexObject = 'member';
      this.policyLogService.updateLog(oldDoc, newDoc);
      this.loading = false;
      resolve();
    });
  }

  async updateLatestProductPaymentStatus(
    policyDoc: Policy,
    productsPaymentStatus: ProductPaymentStatus[]
  ) {
    this.loading = true;
    try {
      await this.db.collection('policy').doc(policyDoc.id).update({
        productsPaymentStatus,
      });
      this.success = true;
      this.selectedPolicyProductPaymentStatusUpdated.emit();
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      throw err;
    }
    this.loading = false;
  }

  async updateIntendedPaymentDay(
    policyDoc: Policy,
    intendedPaymentDay: number,
    reason: string
  ) {
    this.loading = true;
    try {
      if (policyDoc.id) {
        const oldDoc = JSON.parse(JSON.stringify(policyDoc));
        await this.db.collection('policy').doc(policyDoc.id).update({
          intendedPaymentDay,
        });

        if (policyDoc.id !== this.selectedPolicy?.id)
          await this.setSelectedPolicy(policyDoc.id);
        this.policyLogService.indexObject = 'policy';
        this.policyLogService.updateLog(oldDoc, this.selectedPolicy, reason);
        this.selectedPolicyIntendedPaymentDayUpdated.emit();
      }
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
    }
    this.loading = false;
  }

  async updatePolicyDebitOrder(policyDoc: Policy, formValues: DebitOrder) {
    if (policyDoc.id) {
      this.mainService.setLoading(true);
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        cellNumber: this.userService.userData?.cellNumber,
      };
      const createdOn = this.selectedPolicy?.debitOrder?.createdOn
        ? this.selectedPolicy?.debitOrder?.createdOn
        : Timestamp.now();
      const updatedBy = createdBy;
      const updatedOn = createdOn;

      formValues = {
        ...formValues,
        ...{ updatedBy, updatedOn },
      } as DebitOrder;

      let updateData: any = {
        debitOrder: formValues,
      };

      if (formValues.deductionDay !== policyDoc.debitOrder?.deductionDay) {
        updateData.intendedPaymentDay = formValues.deductionDay;
      }

      if (!policyDoc.debitOrder) {
        updateData.debitOrder = {
          ...updateData.debitOrder,
          createdBy,
          createdOn,
        };
      }

      const oldDoc = JSON.parse(JSON.stringify(this.selectedPolicy));

      await this.db
        .collection('policy')
        .doc(policyDoc.id)
        .update(updateData)
        .then(() => {
          this.snackBarService.openBlueSnackBar(
            'DEBIT ORDER UPDATED SUCCESSFULLY!'
          );
        })
        .catch((err) => {
          if (err instanceof Error)
            this.snackBarService.latestError = err.message;
          this.snackBarService.openRedSnackBar('UPDATING DEBIT ORDER FAILED!');
        });

      if (!oldDoc.debitOrder) {
        await this.policyLogService.newDebitOrderLog(formValues, policyDoc);
      } else {
        if (policyDoc.id !== this.selectedPolicy?.id)
          await this.setSelectedPolicy(policyDoc.id);
        this.policyLogService.indexObject = 'debitOrder';
        this.policyLogService.updateLog(oldDoc, this.selectedPolicy);
      }
    }
    this.mainService.setLoading(false);
  }

  // Call this method to update the boolean value
  updateBoolean(updated: boolean) {
    this.updated$.next(updated);
  }

  // Subscribe to this method to get updates on the updated$ boolean value
  getUpdatedBoolean() {
    return this.updated$.asObservable();
  }

  /**
  Sorts an array of PolicyAddOn objects based on the following conditions:
  Active add ons should appear first and should be sorted in ascending order based on their 'createdOn' field.
  Inactive add ons should appear after all the active ones and should be sorted in descending order based on their 'updatedOn' field.
  */
  sortPolicyAddOns(addOns: PolicyAddOn[]): PolicyAddOn[] {
    const requestedAddOns = addOns.filter(
      (addOn) => addOn.status === 'REQUESTED'
    );
    const activeAddOns = addOns.filter((addOn) => addOn.status === 'ACTIVE');
    const inactiveAddOns = addOns.filter(
      (addOn) => addOn.status === 'INACTIVE'
    );

    requestedAddOns.sort((a, b) => {
      const aDate = a.updatedOn?.toDate() || new Date(0);
      const bDate = b.updatedOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      }
      if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    activeAddOns.sort((a, b) => {
      const aDate = a.createdOn?.toDate() || new Date(0);
      const bDate = b.createdOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return 1;
      }
      if (aDate < bDate) {
        return -1;
      }
      return 0;
    });

    inactiveAddOns.sort((a, b) => {
      const aDate = a.updatedOn?.toDate() || new Date(0);
      const bDate = b.updatedOn?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      }
      if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    return [...requestedAddOns, ...activeAddOns, ...inactiveAddOns];
  }

  /**
  Sorts an array of Member objects based on the following conditions:
  The Primary member should always be first.
  The spouse, if one exists, should be second.
  The remaining members should be sorted based on their 'waitingDate' field in descending order.
  Active members should appear before inactive ones.
  */
  sortMembers(members: Member[]): Member[] {
    let primaryMember: any;
    if (this.selectedPolicy)
      primaryMember = this.getPolicyPrimaryMember(this.selectedPolicy);

    const remainingMembers = primaryMember
      ? members.filter((member) => member.id !== primaryMember.id)
      : members;

    const spouse = remainingMembers.find(
      (member) =>
        this.getMemberTypeNameAndClassById(member.memberTypeId || '').text ===
          'SPOUSE' && member.status === 'ACTIVE'
    );

    const otherMembers = remainingMembers.filter(
      (member) => member.id !== spouse?.id
    );

    otherMembers.sort((a, b) => {
      if (a.status !== b.status) {
        return a.status === 'ACTIVE' ? -1 : 1;
      }

      const aDate = a.waitingDate?.toDate() || new Date(0);
      const bDate = b.waitingDate?.toDate() || new Date(0);

      if (aDate > bDate) {
        return -1;
      } else if (aDate < bDate) {
        return 1;
      }
      return 0;
    });

    const sortedMembers = primaryMember ? [primaryMember] : [];
    if (spouse) {
      sortedMembers.push(spouse);
    }
    sortedMembers.push(...otherMembers);

    return sortedMembers;
  }

  public refreshPolicyAddOnDataSource() {
    this.dataSourceAddOns = new MatTableDataSource(this.selectedPolicy?.addOns);
    this.filterService.filterClicked.policyAddOns =
      !this.filterService.filterClicked.policyAddOns;
    this.filterService.toggleInactiveFilter(
      'policyAddOns',
      this.dataSourceAddOns
    );
  }

  public refreshPolicyMemberDataSource() {
    this.dataSourcePolicyMembers = new MatTableDataSource(
      this.selectedPolicy?.members
    );
    this.filterService.filterClicked.policyMembers =
      !this.filterService.filterClicked.policyMembers;
    this.filterService.toggleInactiveFilter(
      'policyMembers',
      this.dataSourcePolicyMembers
    );
  }

  resetSelectedPolicy() {
    this.selectedPolicy = undefined;
    this.policyLogService.selectedPolicyLog = undefined;
    this.policyLogService.currentRefNum = undefined;
    this.dataSourceAddOns = new MatTableDataSource<any>([]);
    this.dataSourcePolicyMembers = new MatTableDataSource<any>([]);
    // this.dataSourceComments = new MatTableDataSource<any>([]);
    this.policyLogService.dataSourcePolicyLogs = new MatTableDataSource<any>(
      []
    );
  }

  unsubscribe() {
    this.policiesSubject.unsubscribe();

    if (this.unsubscribeFromSnapshot) {
      this.unsubscribeFromSnapshot();
    }
  }

  //Close {
  // Unsubscribes from all subscriptions when the component is destroyed
  cleanUp() {
    this.destroy$.next();
    this.unsubscribe();
  }
  //Close }
}
