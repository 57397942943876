import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  signOut,
  RecaptchaVerifier,
} from 'firebase/auth';
import { getApp } from 'firebase/app'; // Correctly import getApp
import { User } from '../models/user.model';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  throttleTime,
} from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { SnackBarService } from './snack-bar.service';
import { MainService } from './main.service';
import { Router } from '@angular/router';
import axios from 'axios';
import { Member, Policy } from '../models/policy.model';
import firebase from 'firebase/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../components/miscellaneous/dialog/dialog.component';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private auth: Auth;
  userData: any | undefined;
  userDoc: AngularFirestoreDocument<User> | undefined;
  selectedUser: User | undefined;

  timeLimit = 10 * 60 * 1000; // 10 minutes of inactivity
  checkInterval = 5 * 60 * 1000; // check every 5 minutes
  private bc: BroadcastChannel | undefined;

  loggingIn: boolean = false;
  isCurrentlyLoggedIn: boolean = false;

  destroy$ = new Subject<void>();
  selectedUser$: Observable<User | undefined> | undefined;
  public authState$ = new BehaviorSubject<boolean | null>(null);
  ngUnsubscribe$ = new Subject<void>();

  selectedUserUpdated = new EventEmitter<void>();
  @Output() routeUserAppropriatelyRequested = new EventEmitter<void>();

  private windowRef: any = window;

  constructor(
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private router: Router,
    private mainService: MainService
  ) {
    // Get the default Firebase app
    // Get the default Firebase app
    const app = getApp(); // Correct usage of getApp
    this.auth = getAuth(app); // Get the Auth instance for the default app
    onAuthStateChanged(this.auth, async (user) => {
      if (user !== null || user !== undefined) {
        this.isCurrentlyLoggedIn = true;
        await this.setUserData(); // Ensure the user data is set before continuing
        this.authState$.next(true);
        this.routeUserAppropriatelyRequested.emit();
      } else {
        this.isCurrentlyLoggedIn = false;
        this.authState$.next(false);
      }
    });
    this.setPersistence();
    this.monitorUserActivity();
    this.startActivityCheckTimer();
  }

  getAuth(): Auth {
    return this.auth;
  }

  private async setPersistence() {
    try {
      await this.afAuth.setPersistence('session');
      console.log('Session persistence set');
    } catch (error) {
      console.error('Error setting persistence: ', error);
    }
  }

  initializeRecaptcha(window: any) {
    this.windowRef = window;
    this.auth = getAuth();
    let recaptchaContainer;
    const currentRoute = this.router.url;

    if (currentRoute.startsWith('/login')) {
      recaptchaContainer = 'recaptcha-container-login';
    } else if (currentRoute.startsWith('/join')) {
      recaptchaContainer = 'recaptcha-container-join';
    }
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      recaptchaContainer ?? '',
      {
        size: 'invisible',
        callback: (response: any) => {},
      }
    );
    this.windowRef.recaptchaVerifier.render().then((widgetId: any) => {
      this.windowRef.recaptchaWidgetId = widgetId;
    });
  }

  async verifyPhoneNumber(phoneNumber: string, idNumber: string): Promise<any> {
    this.mainService.setLoading(true);
    this.mainService.setLoadingInfo('SENDING SMS');
    const appVerifier = this.windowRef.recaptchaVerifier;
    try {
      const validUserResult = await this.validateUser(idNumber, phoneNumber);
      if (validUserResult === 'EXISTING USER') {
        const confirmationResult = await this.afAuth.signInWithPhoneNumber(
          this.toInternationalFormat(phoneNumber),
          appVerifier
        );
        this.windowRef.confirmationResult = confirmationResult;
        this.mainService.setLoading(false);
        return confirmationResult;
      } else if (
        validUserResult === 'NEW USER' &&
        this.router.url.startsWith('/login')
      ) {
        this.mainService.setLoading(false);
        this.newMemberLoginDialog();
        return 'NEW USER';
      } else if (
        validUserResult === 'NEW USER' &&
        this.router.url.startsWith('/join')
      ) {
        const confirmationResult = await this.afAuth.signInWithPhoneNumber(
          this.toInternationalFormat(phoneNumber),
          appVerifier
        );
        this.windowRef.confirmationResult = confirmationResult;
        this.mainService.setLoading(false);
        return confirmationResult;
      } else if (validUserResult === 'INVALID CREDENTIALS') {
        this.mainService.setLoading(false);
        throw new Error(
          'ID NUMBER ALREADY REGISTERED TO A DIFFERENT CELL NUMBER'
        );
      }
    } catch (error: any) {
      this.snackBarService.latestError = error.message;
      this.snackBarService.openRedSnackBar(
        this.snackBarService.latestError
          ? this.snackBarService.latestError
          : 'SMS NOT SENT'
      );
      this.mainService.setLoading(false);
      throw error;
    }
  }

  async verifyCode(
    verificationCode: string,
    idNumber: string
  ): Promise<firebase.User> {
    this.loggingIn = true;
    this.mainService.setLoading(true);
    this.mainService.setLoadingInfo('VERIFYING CODE');
    if (!this.windowRef.confirmationResult) {
      this.snackBarService.openRedSnackBar(
        'NO VERIFICATION CODE PROCESS ONGOING'
      );
      return Promise.reject('No verification code process ongoing.');
    }

    try {
      const result = await this.windowRef.confirmationResult.confirm(
        verificationCode
      );
      this.userData = result.user;

      // Convert phone number to string and ensure it's not undefined or null
      const phoneNumber = this.userData?.phoneNumber
        ? String(this.userData.phoneNumber)
        : null;

      if (phoneNumber && this.auth.currentUser?.uid !== idNumber) {
        await this.saveIdNumberToFirestore(idNumber, phoneNumber);
      }

      this.routeUserAppropriatelyRequested.emit();
      this.loggingIn = false;
      this.mainService.setLoading(false);
      return result.user;
    } catch (error: any) {
      this.mainService.setLoading(false);
      this.snackBarService.openRedSnackBar('INCORRECT CODE ENTERED');
      this.loggingIn = false;
      throw error;
    }
  }

  newMemberLoginDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogType: 'newMemberLoginDialog',
    };
    this.dialog.open(DialogComponent, dialogConfig);
  }

  async validateUser(idNumber: string, phoneNumber: string): Promise<string> {
    try {
      const response = await axios.post(environment.validateUserURL, {
        uid: idNumber,
        phoneNumber: phoneNumber,
        validPortalUser: environment.validPortalUser,
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.snackBarService.latestError = error.message;
        this.snackBarService.openRedSnackBar('ERROR VALIDATING USER');
        console.error('Error validating user:', error);
        throw error;
      } else {
        console.error('Unexpected error:', error);
        throw new Error('Unexpected error occurred');
      }
    }
  }

  async saveIdNumberToFirestore(idNumber: string, phoneNumber: string) {
    try {
      const response = await axios.post(environment.updateUID_URL, {
        uid: idNumber,
        phoneNumber: phoneNumber,
        validPortalUser: environment.validPortalUser,
      });

      const customToken = response.data.token;

      // Sign in with the custom token
      const userCredential = await this.afAuth.signInWithCustomToken(
        customToken
      );
    } catch (error: any) {
      this.snackBarService.latestError = error.message;
      this.snackBarService.openRedSnackBar('ERROR SIGNING IN USER');
      console.error('Error signing in user:', error);
    }
  }

  public toInternationalFormat(phoneNumber: string): string {
    if (phoneNumber.startsWith('0')) {
      return '+27' + phoneNumber.substring(1);
    }
    return phoneNumber; // Return as is if it doesn't start with '0'
  }

  async setUserData(): Promise<void> {
    const currentUserId = this.getAuth().currentUser?.uid;
    if (!currentUserId) {
      throw new Error('User not authenticated');
    }
    const currentMemberIdAndCellNumber =
      currentUserId + this.getAuth().currentUser?.phoneNumber;

    this.db
      .collection('policy', (ref) =>
        ref.where(
          'memberIdAndCellNumbers',
          'array-contains',
          currentMemberIdAndCellNumber
        )
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          let userData: Member | undefined;
          for (const action of actions) {
            const policyData = action.payload.doc.data() as Policy;
            if (policyData.members) {
              userData = policyData.members.find(
                (member) => member.idNumber === currentUserId
              );
              if (userData) {
                break;
              }
            }
          }
          if (!userData) {
            throw new Error(
              'No member found for the current user in the policies'
            );
          }
          return userData;
        }),
        catchError((error) => {
          throw error;
        })
      )
      .subscribe({
        next: (member) => {
          let displayName = member.firstName + ' ' + member.lastName;
          this.userData = {
            ...member,
            uid: currentUserId,
            displayName: displayName,
          };
        },
        error: (err) => {
          console.error('Subscription error:', err);
        },
      });
  }

  // async setUserData(user?: User): Promise<void> {
  //   if (!user || !this.auth.currentUser?.uid) {
  //     // If no user or current user UID is not available, resolve immediately
  //     this.snackBarService.openRedSnackBar('USER HAS NO AUTH');
  //     return Promise.resolve();
  //   }

  //   const userRef: AngularFirestoreDocument<any> = this.db.doc(
  //     `portalUsers/${this.auth.currentUser.uid}`
  //   );

  //   const updatedBy: UpdatedBy = {
  //     uid: this.auth.currentUser.uid,
  //     displayName: user.displayName,
  //     email: user.email,
  //     cellNumber: user.cellNumber,
  //   };
  //   const updatedOn = Timestamp.now();

  //   const userData: User = {
  //     uid: this.auth.currentUser.uid,
  //     email: user.email || '',
  //     displayName: user.displayName,
  //     photoURL: user.photoURL || '',
  //     cellNumber: user.cellNumber || '',
  //     roleId: user.roleId || '',
  //     status: user.status || '',
  //     updatedBy,
  //     updatedOn,
  //   };

  //   return userRef
  //     .set(userData, { merge: true })
  //     .then(() => {
  //       this.userData = userData; // Assuming you're storing the user data in a service level state variable
  //     })
  //     .catch((err) => {
  //       const errorMessage =
  //         err instanceof Error ? err.message : 'Unknown error';
  //       this.snackBarService.latestError = errorMessage; // Assuming error handling is managed here
  //       this.snackBarService.openRedSnackBar(
  //         'THERE WAS AN ERROR WITH UPDATING THE USER ACCOUNT'
  //       );
  //       // Optionally re-throw the error if you need further error handling outside this function
  //       return Promise.reject(err);
  //     });
  // }

  // Sets the selectedUser property to the given user
  // async setSelectedUser(userId: string): Promise<void> {
  //   try {
  //     this.userDoc = this.db.collection<User>('users').doc(userId);
  //     this.selectedUser$ = this.userDoc.snapshotChanges().pipe(
  //       map((action) => ({
  //         id: action.payload.id,
  //         ...(action.payload.data() as User),
  //       }))
  //     );

  //     return new Promise<void>((resolve, reject) => {
  //       this.selectedUser$
  //         ?.pipe(takeUntil(this.destroy$))
  //         .subscribe(async (user: User | undefined) => {
  //           try {
  //             if (user) {
  //               this.selectedUser = user;
  //               this.selectedUserUpdated.emit();
  //               resolve();
  //             } else {
  //               throw new Error('No user found');
  //             }
  //           } catch (err) {
  //             if (err instanceof Error) {
  //               this.snackBarService.latestError = err.message;
  //               this.snackBarService.openRedSnackBar(
  //                 'SETTING THE SELECTED USER FAILED!'
  //               );
  //               reject(err);
  //             }
  //           }
  //         });
  //     });
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       this.snackBarService.latestError = err.message;
  //       this.snackBarService.openRedSnackBar(
  //         'SETTING THE SELECTED USER FAILED!'
  //       );
  //       throw err;
  //     }
  //   }
  // }

  monitorUserActivity() {
    const activityEvents = merge(
      fromEvent(window, 'load'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'keydown'),
      fromEvent(window, 'scroll')
    );

    activityEvents.pipe(throttleTime(2000)).subscribe(() => {
      this.bc?.postMessage('activity');
      this.updateHeartbeat();
    });
  }

  updateHeartbeat() {
    sessionStorage.setItem('lastHeartbeat', Date.now().toString());
  }

  startActivityCheckTimer() {
    interval(this.checkInterval)
      .pipe(
        map(() => Number(sessionStorage.getItem('lastHeartbeat'))),
        filter(
          (lastHeartbeat) =>
            this.isCurrentlyLoggedIn &&
            Date.now() - lastHeartbeat > this.timeLimit
        )
      )
      .subscribe(() => {
        this.signOut();
      });
  }

  // Sign out
  async signOut() {
    const auth = getAuth();
    try {
      this.destroy$.next();
      // this.rolesRightsService.cleanUp();

      await new Promise((resolve) => setTimeout(resolve, 500));
      await signOut(auth);
      this.mainService.setPreviousRoute(this.router.url);
      localStorage.removeItem('user');
      this.router.navigateByUrl('/login');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'THERE WAS AN ERROR SIGNING YOU OUT'
      );
    }
  }
}
