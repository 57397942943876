import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphanumericOnly]',
})
export class AlphanumericOnlyDirective {
  constructor(private el: ElementRef) {}

  // Method to restrict input to only alphanumeric characters (letters and numbers) and convert to uppercase
  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue
      .replace(/[^a-zA-Z0-9]/g, '')
      .toUpperCase();
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
