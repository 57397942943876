import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, firstValueFrom } from 'rxjs';
import { Log } from '../models/log.model';
import { SnackBarService } from './snack-bar.service';
import { Policy, branchCodeBankMap } from '../models/policy.model';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MemberPolicySearch } from '../models/search.model';
import { MainService } from './main.service';
import { PlanService } from './plan.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(
    private db: AngularFirestore,
    private snackBarService: SnackBarService,
    private planService: PlanService,
    private fireFunctions: AngularFireFunctions,
    private mainService: MainService
  ) {}

  validateIdNumber(id: string): boolean {
    if (id.length !== 13) {
      return false;
    }

    const MM = parseInt(id.slice(2, 4), 10);
    const DD = parseInt(id.slice(4, 6), 10);

    // Check month
    if (MM < 1 || MM > 12) {
      return false;
    }

    // Check day based on month (February has 29 days on a leap year)
    if (
      DD < 1 ||
      DD > 31 ||
      (MM === 2 && DD > 29) ||
      (DD > 30 && [4, 6, 9, 11].includes(MM))
    ) {
      return false;
    }

    // Check citizenship code
    const CC = parseInt(id.slice(10, 11), 10);
    if (CC > 1) {
      return false;
    }

    // Luhn algorithm for check digit
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      let num = parseInt(id.charAt(i), 10);
      if (i % 2 !== 0) {
        num *= 2;
        if (num > 9) {
          num -= 9;
        }
      }
      sum += num;
    }

    const checkDigit = 10 - (sum % 10);
    return checkDigit % 10 === parseInt(id.charAt(12), 10);
  }

  getPotentialAgesFromSAId(idNumber: string): [number, number] {
    const currentYear = new Date().getFullYear();

    const yearFromId = +idNumber.substring(0, 2);

    const ageWith2000s = currentYear - (2000 + yearFromId);
    const ageWith1900s = currentYear - (1900 + yearFromId);

    return [ageWith2000s, ageWith1900s];
  }

  validateIdNumberAge(id: string, minAge: number, maxAge: number): boolean {
    const [age2000s, age1900s] = this.getPotentialAgesFromSAId(id);

    const isAge2000sValid =
      age2000s >= minAge && age2000s <= maxAge && age2000s >= 0;
    const isAge1900sValid =
      age1900s >= minAge && age1900s <= maxAge && age1900s >= 0;

    // If only one of the ages is valid, return true
    return (
      (isAge2000sValid && isAge1900sValid) ||
      (isAge2000sValid && age1900s < 0) ||
      (isAge1900sValid && age2000s < 0)
    );
  }

  /**
   * Check if a reference number exists in the policyLog collection and that its policyId is not the provided one
   * @param referenceNumber The reference number to check
   * @param policyId The policyId to exclude
   * @returns Promise<Log | null> The document data if a document with the reference number exists and its policyId is not the provided one, null otherwise
   */
  async checkReferenceNumberExists(
    referenceNumber: string,
    policyId: string
  ): Promise<Log | null> {
    const logRef = this.db.collection<Log>('policyLog', (ref) =>
      ref
        .where('referenceNumbers', 'array-contains', referenceNumber)
        .where('policyId', '!=', policyId)
        .limit(1)
    );

    const snapshot = await firstValueFrom(logRef.get());

    if (!snapshot.empty) {
      return snapshot.docs[0].data() as Log;
    }
    return null;
  }

  async searchDuplicateIdNumber(
    idNumber: string
  ): Promise<MemberPolicySearch[] | undefined> {
    // Fetch policies with matching members' ID numbers.
    const policiesWithMatchingId: Policy[] = await firstValueFrom(
      this.db
        .collection<Policy>('policy', (ref) =>
          ref.where('memberIdNumbers', 'array-contains', idNumber)
        )
        .valueChanges()
    );

    const memberSearchResults: MemberPolicySearch[] = [];

    for (const policy of policiesWithMatchingId) {
      for (const member of policy.members || []) {
        if (member.idNumber === idNumber) {
          let planName = '(NOT FOUND)'; // default value
          if (policy.planId) {
            // Try to get the plan name.
            const plan = await this.planService.getPlanNameById(policy.planId);
            // If the plan has a text property, use it as the plan name. Otherwise, stick with the default.
            planName = plan?.text || planName;
          }
          memberSearchResults.push({
            policyNumber: policy.policyNumber || '',
            idNumber: member.idNumber,
            firstName: member.firstName || '',
            lastName: member.lastName || '',
            plan: planName,
            memberTypeId: member.memberTypeId || '',
            memberStatus: member.status || '',
            planStatus: policy.status || '',
            waitingDate: member.waitingDate || '',
            policyId: policy.id || '',
          });
        }
      }
    }

    if (memberSearchResults.length === 0) {
      return undefined;
    }

    return memberSearchResults;
  }

  async searchDuplicatePolicyNumber(policyNumber: string): Promise<boolean> {
    const observable$ = this.db
      .collection<Policy>('policy', (ref) =>
        ref.where('policyNumber', '==', policyNumber).limit(1)
      )
      .valueChanges()
      .pipe(map((policies) => policies.length > 0));

    const result = await firstValueFrom(observable$);
    return result;
  }

  allowedDaysValidator(allowedDays: number[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (allowedDays.includes(control.value)) {
        return null; // validation passes
      }
      return { invalidDay: { value: control.value } }; // validation fails
    };
  }

  async validateBankAccount(
    accountNumber: string,
    branchCode: string,
    accountType: number
  ) {
    this.mainService.setLoading(true);

    const validateBankAccountCallable = this.fireFunctions.httpsCallable(
      'validateBankAccount'
    );
    try {
      const resultObservable = validateBankAccountCallable({
        accountNumber,
        branchCode,
        accountType,
      });

      const result = await firstValueFrom(resultObservable);
      const validationCode = Number(result.ValidateBankAccountResult); // Convert to number

      switch (validationCode) {
        case 0:
          this.snackBarService.latestError = 'Bank account details valid';
          // Handle valid case
          break;
        case 1:
          this.snackBarService.latestError = 'Invalid branch code';
          // Handle invalid branch code
          break;
        case 2:
          this.snackBarService.latestError =
            'Account number failed check digit validation';
          // Handle account number check digit failure
          break;
        case 3:
          this.snackBarService.latestError = 'Invalid account type';
          // Handle invalid account type
          break;
        case 4:
          this.snackBarService.latestError = 'Input data incorrect';
          // Handle incorrect input data
          break;
        case 100:
          this.snackBarService.latestError = 'Authentication failed';
          // Handle authentication failure
          break;
        case 200:
          this.snackBarService.latestError =
            'Web service error contact support@netcash.co.za';
          // Handle web service error
          break;
        default:
          this.snackBarService.latestError = 'Unknown response code';
        // Handle unknown response
      }
      this.mainService.setLoading(false);

      return validationCode === 0; // Return true if valid, false otherwise
    } catch (error) {
      this.mainService.setLoading(false);
      console.error('Error calling validateBankAccount Cloud Function:', error);
      return false;
    }
  }

  // conditionalValidator(validator: ValidatorFn): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     // Check if the control is part of a FormGroup
  //     if (control.parent instanceof FormGroup) {
  //       const formGroup = control.parent;

  //       // Count the non-empty controls
  //       const nonEmptyControlsCount = Object.keys(formGroup.controls).filter(
  //         (key) => {
  //           const siblingControl = formGroup.get(key);
  //           return siblingControl && siblingControl.value;
  //         }
  //       ).length;

  //       // If there is more than one non-empty control, and this control is empty and required, return an error
  //       if (
  //         nonEmptyControlsCount > 1 &&
  //         !control.value &&
  //         validator === Validators.required
  //       ) {
  //         return { required: true };
  //       }
  //     }

  //     // If the control has a value or if it's not the required validator, apply the validator function
  //     if (control.value || validator !== Validators.required) {
  //       return validator(control);
  //     }

  //     // Otherwise, no error
  //     return null;
  //   };
  // }
}
