import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { PolicyLogService } from '../../../services/policy-log.service';
import { PolicyService } from '../../../services/policy.service';

@Component({
  selector: 'policy-stepper',
  templateUrl: './policy-stepper.component.html',
  styleUrls: ['./policy-stepper.component.scss'],
})
export class PolicyStepperComponent {
  showStepper = false;
  selectedIndex = 0;

  constructor(
    private router: Router,
    public policyService: PolicyService,
    public policyLogService: PolicyLogService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.url;
        this.showStepper =
          currentRoute.startsWith('/policy-details') ||
          currentRoute.startsWith('/transaction-pdf');

        if (this.showStepper) {
          if (currentRoute.startsWith('/policy-details')) {
            this.selectedIndex = 0;
          } else if (currentRoute.startsWith('/transaction-pdf')) {
            this.selectedIndex = 1;
          }
        }
      });
  }

  onRouteChange(selectedIndex: number): void {
    switch (selectedIndex) {
      case 0:
        this.router.navigate([
          `/policy-details`,
          this.policyService.selectedPolicy?.id,
        ]);
        break;
      case 1:
        this.router.navigate([
          '/transaction-pdf',
          this.policyService.selectedPolicy?.id,
        ]);
        break;
    }
  }
}
