import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[textOnly]',
})
export class TextOnlyDirective {
  constructor(private el: ElementRef) {}

  // Method to restrict input to only text characters (letters, spaces, hyphens, and extended Latin characters)
  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(/[^\p{L} -]/gu, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
