<ng-container *ngIf="showStepper">
  <div class="pt15">
    <custom-stepper
      [steps]="[{ label: 'Policy Pdf' }, { label: 'Transaction Pdf' }]"
      [(selectedIndex)]="selectedIndex"
      [enabledSteps]="[
        true,
        !!policyService.selectedPolicy,
        !!policyService.selectedPolicy
      ]"
      (routeChange)="onRouteChange($event)"
    ></custom-stepper>
  </div>
</ng-container>
