import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { uuidv4 } from '@firebase/util';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  firstValueFrom,
  map,
  takeUntil,
} from 'rxjs';
import { MemberType, Plan } from '../models/plan.model';
import { SnackBarService } from './snack-bar.service';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Member, Policy } from '../models/policy.model';
import { DateTimeService } from './date-time.service';
import { Router } from '@angular/router';
import { MainService } from './main.service';
import { FilterService } from './filter.service';
import { assignWith } from 'lodash-es';
@Injectable({
  providedIn: 'root',
})
export class PlanService {
  selectedPlan: Plan | undefined;
  allPlans: Plan[] | undefined;

  dataSourcePlans: MatTableDataSource<any> | undefined;
  dataSourceMemberTypes: MatTableDataSource<any> | undefined;
  dataSourceMemberTypePremiums: MatTableDataSource<any> | undefined;

  planDoc: AngularFirestoreDocument<Plan> | undefined;
  selectedPlan$: Observable<Plan | undefined> = EMPTY;
  selectedPlanMemberType: MemberType | undefined;

  selectedPlanMemberTypeIndex: number | undefined;

  selectedPlanUpdated = new EventEmitter<void>();
  selectedMemberTypesUpdated = new EventEmitter<void>();
  selectedPlanMemberTypeUpdated = new EventEmitter<void>();
  selectedMemberTypePremiumsUpdated = new EventEmitter<void>();

  @ViewChild(MatSort) sort: MatSort | undefined;

  destroy$ = new Subject<void>();

  // Form state
  loading = false;
  success = false;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private dateTimeService: DateTimeService,
    private filterService: FilterService,
    private router: Router,
    private mainService: MainService
  ) {
    this.userService.destroy$?.pipe()?.subscribe(() => this.cleanUp());
  }

  loadPlans() {
    return new Promise<void>((resolve, reject) => {
      this.db
        .collection<Plan>('plan', (ref) => ref.orderBy('name'))
        .valueChanges({ idField: 'id' })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (plans) => {
            this.dataSourcePlans = new MatTableDataSource(plans);
            this.dataSourcePlans.sort = this.sort || null;
            this.setAllPlans(plans);
            this.filterService.filterClicked.plans = false;
            this.filterService.toggleInactiveFilter(
              'plans',
              this.dataSourcePlans
            );
            resolve();
          },
          error: (err) => {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('LOADING PLANS FAILED!');
            reject(err);
          },
        });
    });
  }

  // Sets the selectedPlan property to the given plan
  async setSelectedPlan(planId?: string): Promise<void> {
    if (!planId) {
      this.router.navigate(['/products']);
      throw new Error('PLAN ID NOT PROVIDED!');
    }

    sessionStorage.setItem('selectedPlanId', planId);

    this.planDoc = this.db.collection<Plan>('plan').doc(planId);

    this.selectedPlan$ = this.planDoc.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists) {
          return {
            id: action.payload.id,
            ...(action.payload.data() as Plan),
          };
        } else {
          this.snackBarService.openRedSnackBar('PLAN DOCUMENT NOT FOUND!');
          throw new Error('PLAN DOCUMENT NOT FOUND!');
        }
      }),
      catchError((err) => {
        this.handleError(err);
        throw err;
      })
    );

    await new Promise<void>((resolve, reject) => {
      this.selectedPlan$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (plan) => {
          const storedPlanId = sessionStorage.getItem('selectedPlanId');
          if (plan && plan.id === storedPlanId) {
            this.selectedPlan = plan;
            this.refreshPlanMembers();
            this.refreshSelectedPlanMemberType();
            this.selectedPlanUpdated.emit();
            resolve();
          }
        },
        error: (err) => {
          if (err instanceof Error) {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('ERROR SETTING SELECTED PLAN');
          }
          reject(err);
        },
      });
    });
  }

  private handleError(err: Error) {
    if (err.message) this.snackBarService.latestError = err.message;

    this.mainService.setLoading(false);
    this.snackBarService.openRedSnackBar('SETTING THE SELECTED PLAN FAILED!');
    this.router.navigate(['/products']);
  }

  // Sets the allPlans property to the given array of plans
  setAllPlans(plans: Plan[]) {
    this.allPlans = plans;
  }

  // Returns the ID of the plan with the given name.
  getPlanIdByName(planName: string) {
    return this.allPlans?.find((plan) => plan.name === planName)?.id;
  }

  getCurrentMemberStandardPremiumById(
    planId: string,
    memberTypeId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const memberPremiumHistory = (
      this.selectedPlan?.id === planId
        ? this.selectedPlan.memberType
        : this.getPlanMemberTypes(planId)
    )?.find((memberType) => memberType.id === memberTypeId)?.premium;

    if (!memberPremiumHistory) return undefined;

    const sortedPremiums = memberPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const now = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = now;

    if (timestamp) {
      const providedTimestamp = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestamp <= now) {
        adjustedTimestamp = providedTimestamp;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.standardAmount : undefined;
  }

  getCurrentMemberWaitingPremiumById(
    planId: string,
    memberTypeId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const memberPremiumHistory = (
      this.selectedPlan?.id === planId
        ? this.selectedPlan.memberType
        : this.getPlanMemberTypes(planId)
    )?.find((memberType) => memberType.id === memberTypeId)?.premium;

    if (!memberPremiumHistory) return undefined;

    const sortedPremiums = memberPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const now = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = now;

    if (timestamp) {
      const providedTimestamp = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestamp <= now) {
        adjustedTimestamp = providedTimestamp;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.waitingAmount : undefined;
  }

  // Returns the name of the plan with the given ID
  getPlanNameById(planId: string) {
    return this.allPlans?.find((plan) => plan.id === planId)?.name
      ? {
          text: this.allPlans.find((plan) => plan.id === planId)?.name,
          class: [''],
        }
      : { text: '(NOT FOUND)', class: ['italic'] };
  }

  // Returns the plan with the given ID
  getPlanById(planId: string) {
    return this.allPlans?.find((plan) => plan.id === planId);
  }

  // Returns an array of member types for the plan with the given ID
  getPlanMemberTypes(planId: string) {
    return this.allPlans?.find((plan) => plan.id === planId)?.memberType;
  }

  getAllPlanMemberTypes(): MemberType[] {
    const memberTypesSet = new Set<MemberType>();

    this.allPlans?.forEach((plan) => {
      if (plan.memberType && Array.isArray(plan.memberType)) {
        plan.memberType.forEach((memberType) => {
          memberTypesSet.add(memberType);
        });
      }
    });

    return Array.from(memberTypesSet);
  }

  // Returns the ID of the member type with the given name, for the plan with the given ID
  getMemberTypeIdByName(planId: string, memberTypeName: string) {
    return this.getPlanMemberTypes(planId)?.find(
      (memberType) => memberType.name === memberTypeName
    )?.id;
  }

  getPlanPrimaryMemberType(planId: string) {
    // Find the plan with the given planId

    const plan = this.allPlans?.find((p) => p.id === planId);

    // If a plan was found and it has member types...
    if (plan && plan.memberType) {
      // Find the primary member from the member types.
      const primaryMemberType = plan.memberType.find((mt) => mt.primaryMember);

      // If a primary member type was found, return its name.
      if (primaryMemberType) {
        return primaryMemberType;
      }
    }

    // Return null if no primary member type was found for the given planId.
    return undefined;
  }

  getAllPrimaryMemberTypes() {
    let primaryMemberTypes: string[] = [];

    this.allPlans?.forEach((plan) => {
      if (plan.id) {
        const primaryMemberId = this.getPlanPrimaryMemberType(plan.id)?.id;

        if (primaryMemberId) primaryMemberTypes.push(primaryMemberId);
      }
    });

    return primaryMemberTypes;
  }

  // Returns the name of the member type with the given ID.
  // If a planId is provided, it searches only within that plan. Otherwise, it searches all plans.
  getMemberTypeNameById(memberTypeId: string, planId: any = null) {
    if (planId) {
      return (
        this.getPlanMemberTypes(planId)?.find(
          (memberType) => memberType.id === memberTypeId
        )?.name ?? { text: '(NOT FOUND)', class: ['italic'] }
      );
    } else {
      if (this.allPlans)
        for (const plan of this.allPlans) {
          const memberType = plan.memberType?.find(
            (type: any) => type.id === memberTypeId
          );
          if (memberType) {
            return (
              memberType.name ?? { text: '(NOT FOUND)', class: ['italic'] }
            );
          }
        }
    }
    return '';
  }

  // Returns the member type with the given ID
  getMemberTypeById(memberTypeId: string) {
    if (memberTypeId) {
      if (this.allPlans)
        for (const plan of this.allPlans) {
          const memberType = plan.memberType?.find(
            (type) => type.id === memberTypeId
          );
          if (memberType) {
            return memberType;
          }
        }
    }
    return {};
  }

  async getMemberTypeAgeRange(planId: string, memberTypeId: string) {
    // Try to find the plan in the preloaded array first
    const plan = this.allPlans?.find((p) => p.id === planId);

    if (plan) {
      const memberType = plan.memberType?.find((mt) => mt.id === memberTypeId);
      if (memberType) {
        return {
          ageFrom: memberType.ageFrom,
          ageTo: memberType.ageTo,
        };
      }
    } else {
      // If plan was not found in the preloaded array, fetch it from Firestore
      const planData: Plan = await this.db
        .collection('plans')
        .doc(planId)
        .ref.get()
        .then((doc) => doc.data() as Plan);
      const memberType = planData?.memberType?.find(
        (mt) => mt.id === memberTypeId
      );
      if (memberType) {
        return {
          ageFrom: memberType.ageFrom,
          ageTo: memberType.ageTo,
        };
      }
    }

    // Return null or any default value if not found
    return null;
  }

  async getActivePolicyPrimaryMember(
    planId: string
  ): Promise<Member | undefined> {
    return new Promise((resolve, reject) => {
      const sub = this.db
        .collection<Policy>('policy', (ref) =>
          ref.where('planId', '==', planId).where('status', '==', 'ACTIVE')
        )
        .snapshotChanges()
        .subscribe({
          next: (actions) => {
            const plan = this.allPlans?.find((p) => p.id === planId);
            if (plan) {
              const primaryMemberType = plan.memberType?.find(
                (mt) => mt.primaryMember
              );
              if (primaryMemberType) {
                for (let action of actions) {
                  const policy: Policy = action.payload.doc.data() as Policy;
                  const activePrimaryMember = policy.members?.find(
                    (member) =>
                      member.status === 'ACTIVE' &&
                      member.memberTypeId === primaryMemberType.id
                  );
                  if (activePrimaryMember) {
                    resolve(activePrimaryMember);
                    return;
                  }
                }
              }
            }
            resolve(undefined);
          },
          error: (err) => {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar(
              'ERROR FINDING AN ACTIVE PRIMARY MEMBER!'
            );
            reject(err);
          },
          complete: () => {
            sub.unsubscribe();
          },
        });
    });
  }

  public refreshPlanMembers() {
    if (this.selectedPlan)
      this.dataSourceMemberTypes = new MatTableDataSource(
        this.selectedPlan.memberType
      );
    this.filterService.filterClicked.planMembers = false;

    if (this.dataSourceMemberTypes)
      this.filterService.toggleInactiveFilter(
        'planMembers',
        this.dataSourceMemberTypes
      );
  }

  public refreshSelectedPlanMemberType() {
    if (
      this.selectedPlan?.memberType &&
      this.selectedPlanMemberTypeIndex &&
      this.selectedPlanMemberTypeIndex >= 0 &&
      this.selectedPlan?.memberType[this.selectedPlanMemberTypeIndex]
    ) {
      this.selectedPlanMemberType =
        this.selectedPlan.memberType[this.selectedPlanMemberTypeIndex];
      this.dataSourceMemberTypePremiums = new MatTableDataSource(
        this.selectedPlanMemberType?.premium
      );
      this.selectedPlanMemberTypeUpdated.emit();
    }
  }

  resetSelectedPlan() {
    this.selectedPlan = undefined;
    this.dataSourceMemberTypes = new MatTableDataSource<any>([]);
  }

  cleanUp() {
    this.destroy$.next();
  }
}
