import { Timestamp } from '@firebase/firestore';
import { CRUD, CreatedBy, UpdatedBy } from './general.model';
export interface User {
  uid?: string | null;
  email?: string | null;
  displayName?: string | null;
  photoURL?: string | null;
  status?: string;
  roleId?: string;
  cellNumber?: string;
  updatedOn?: Timestamp;
  updatedBy?: UpdatedBy;
}

export interface RoleRights {
  users?: CRUD;
  policies?: CRUD;
  products?: CRUD;
  transactions?: CRUD;
}

export interface Role extends RoleRights {
  id?: string;
  name?: string;
  status?: string;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export type RoleWithRightsIndex = Role & {
  [category in keyof RoleRights]?: RoleRights[category];
};

export enum UserStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
