<div *ngIf="isLoading" class="spinner-container">
  <app-custom-spinner mode="indeterminate"></app-custom-spinner>
</div>
<div *ngIf="isLoading" class="loadingInfo-container">
  <h2 class="whiteColor">{{ loadingInfo }}</h2>
</div>
<div
  *ngIf="isDisabled"
  [ngClass]="{ 'disabled-container': true, loadingCursor: loadingCursor }"
></div>
<div *ngIf="showLogoutButton" class="logout-button">
  <div>
    <button mat-flat-button color="primary" (click)="userService.signOut()">
      <mat-icon>logout</mat-icon>
      LOGOUT
    </button>
  </div>
</div>
<div *ngIf="showLoginButton && !showLogoutButton" class="logout-button">
  <div>
    <button
      matTooltip="ALREADY HAVE A POLICY"
      mat-flat-button
      color="primary"
      (click)="routeToLogin()"
    >
      LOGIN
    </button>
  </div>
</div>

<policy-stepper></policy-stepper>
<router-outlet></router-outlet>
