import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss'],
})
export class CustomStepperComponent {
  @Input() steps: { label: string }[] = [];
  @Input() selectedIndex: number = 0;
  @Input() enabledSteps: boolean[] = [];
  @Output() selectedIndexChange = new EventEmitter<number>();
  @Output() routeChange = new EventEmitter<number>();

  constructor(public mainService: MainService) {}

  stepClicked(index: number): void {
    if (this.enabledSteps[index]) {
      this.selectedIndex = index;
      this.selectedIndexChange.emit(index);
      this.routeChange.emit(index);
    }
  }
}
