import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'join',
    loadChildren: () =>
      import('./components/auth/join/join.module').then((m) => m.JoinModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'select-policy',
    loadChildren: () =>
      import('./components/select-policy/select-policy.module').then(
        (m) => m.SelectPolicyModule
      ),
  },
  {
    path: 'policy-member-details/:policyId',
    loadChildren: () =>
      import(
        './components/policy-member-details/policy-member-details.module'
      ).then((m) => m.PolicyMemberDetailsModule),
  },
  {
    path: 'dialog',
    loadChildren: () =>
      import('./components/miscellaneous/dialog/dialog.module').then(
        (m) => m.DialogModule
      ),
  },
  {
    path: 'policy-pdf/:policyId',
    loadChildren: () =>
      import('./components/policy-pdf/policy-pdf.module').then(
        (m) => m.PolicyPdfModule
      ),
  },
  {
    path: 'policy-details/:policyId',
    loadChildren: () =>
      import('./components/policy-pdf/policy-pdf.module').then(
        (m) => m.PolicyPdfModule
      ),
  },
  {
    path: 'transaction-pdf/:policyId',
    loadChildren: () =>
      import('./components/transaction-pdf/transaction-pdf.module').then(
        (m) => m.TransactionPdfModule
      ),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
