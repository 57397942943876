import { Injectable } from '@angular/core';
import { Timestamp } from '@firebase/firestore';
import Holidays from 'date-holidays';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {}

  // Convert a timestamp to a Date object
  timestampToDate(timestamp: Timestamp | undefined): Date | null {
    return timestamp ? this.verifyTimestamp(timestamp).toDate() : null;
  }

  algoliaTimestampToDate(algoliaTimestamp: any): Date {
    return this.algoliaTimestampToFirestoreTimestamp(algoliaTimestamp).toDate();
  }

  private algoliaTimestampToFirestoreTimestamp(
    algoliaTimestamp: any
  ): Timestamp {
    // Firestore timestamp needs to be in seconds, so convert from ms to s
    const seconds = Math.floor(algoliaTimestamp / 1000);
    // get the remaining milliseconds and convert to nanoseconds
    const nanoseconds = (algoliaTimestamp % 1000) * 1e6;

    return new Timestamp(seconds, nanoseconds);
  }

  // Converts a JavaScript Date object to a Firebase Firestore timestamp
  dateToTimestamp(date: Date | undefined): Timestamp | null {
    if (date instanceof Date) {
      return Timestamp.fromDate(date);
    } else if (date === undefined) {
      return null;
    } else {
      throw new Error(
        'dateToTimestamp requires a Date object or undefined as argument, received: ' +
          typeof date
      );
    }
  }

  secondsToDate(seconds: number): Date {
    return new Date(seconds * 1000);
  }

  verifyTimestamp(timestamp: any): Timestamp {
    if (typeof timestamp === 'object' && 'seconds' in timestamp) {
      return new Timestamp(timestamp.seconds, 0);
    } else if (typeof timestamp === 'number') {
      return this.algoliaTimestampToFirestoreTimestamp(timestamp);
    } else if (timestamp instanceof Timestamp) {
      return timestamp;
    } else if (timestamp instanceof Date) {
      const timestampFromDate = this.dateToTimestamp(timestamp);
      if (timestampFromDate != null) return timestampFromDate;
    }
    throw new Error('Invalid timestamp format');
  }

  secondsToDateFormat(seconds: number | undefined): string {
    if (seconds) {
      const date = new Date(seconds * 1000);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    } else {
      return '-';
    }
  }

  setHoursToMidnight(timestamp: Timestamp): Timestamp {
    const date = new Date(timestamp.toMillis());
    date.setHours(0, 0, 0, 0);
    return Timestamp.fromDate(date);
  }

  getDaysDifference(date1: Timestamp, date2: Timestamp | undefined): number {
    if (!(date2 instanceof Timestamp)) {
      return 0;
    }

    // Convert Timestamp objects to JavaScript Date objects
    const jsDate1 = date1.toDate();
    const jsDate2 = date2.toDate();

    // Calculate the time difference in milliseconds
    const timeDifference = jsDate1.getTime() - jsDate2.getTime();

    // Convert time difference to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  }

  hasDateChanged(formValueDate: any, selectedDate: any): boolean {
    const selectedDateObj = this.timestampToDate(selectedDate);

    let formValueDateObj = formValueDate;

    if (formValueDateObj instanceof Timestamp)
      formValueDateObj = this.timestampToDate(formValueDate);

    if (formValueDateObj instanceof Date && selectedDateObj instanceof Date) {
      formValueDateObj.setHours(0, 0, 0, 0);

      selectedDateObj.setHours(0, 0, 0, 0);

      return formValueDateObj.getTime() !== selectedDateObj.getTime();
    }

    return false;
  }

  timestampToNumber(timestamp: Timestamp): number {
    return timestamp.toMillis();
  }

  formatDate(date: Date | Timestamp | undefined, includeDay = true) {
    if (date) {
      if (date instanceof Timestamp) date = date.toDate();

      if (date instanceof Date) {
        let day = date.getDate();
        let month = date.toLocaleString('default', { month: 'long' });
        let year = date.getFullYear();

        if (includeDay) {
          return `${day} ${month.toUpperCase()} ${year}`;
        } else {
          return `${month.toUpperCase()} ${year}`;
        }
      }
    }
    return ``;
  }

  formatDateTime(date: Date | Timestamp | undefined) {
    if (date) {
      if (date instanceof Timestamp) date = date.toDate();

      if (date instanceof Date) {
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
        let year = date.getFullYear();
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
    }
    return ``;
  }

  formatDateNoTime(date: Date | Timestamp | undefined) {
    if (date) {
      if (date instanceof Timestamp) date = date.toDate();

      if (date instanceof Date) {
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
        let year = date.getFullYear();

        return `${day}/${month}/${year}`;
      }
    }
    return ``;
  }

  getZeroHourDate(): Date {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  }

  formatDateToUrlFormat(date: Date): string {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
  }

  convertUrlDateToDate(dateStr: string | null): Date | null {
    if (!dateStr) return null;

    const [dd, mm, yyyy] = dateStr.split('-').map((part) => parseInt(part, 10));

    if (!dd || !mm || !yyyy) return null;

    return new Date(yyyy, mm - 1, dd);
  }

  verifyWaitingDate(
    waitingDate: Timestamp | undefined,
    newInceptionDate: Timestamp,
    oldInceptionDate: Timestamp
  ) {
    if (
      waitingDate !== undefined &&
      this.hasDateChanged(newInceptionDate, oldInceptionDate) &&
      oldInceptionDate.toMillis() === waitingDate.toMillis()
    ) {
      const adjustedDate = new Date(newInceptionDate.toDate().getTime());
      adjustedDate.setHours(0, 0, 0, 0);
      return this.dateToTimestamp(adjustedDate) ?? newInceptionDate;
    }

    return waitingDate;
  }

  /**
   * Converts a Firestore Timestamp to a string in 'YYYYMMDD' format.
   * @param {Timestamp} timestamp - The Firestore Timestamp to convert.
   * @return {string} A string representing the date in 'YYYYMMDD' format.
   */
  timestampToString(timestamp: Timestamp): string {
    // Convert Firestore Timestamp to JavaScript Date object
    const date = timestamp.toDate();

    // Format the date as 'YYYYMMDD'
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
  }

  /**
   * Extracts the date of birth from a South African ID number and formats it as CCYYMMDD.
   * @param {string} idNumber - The South African ID number.
   * @return {string | undefined} - The date of birth in 'CCYYMMDD' format, or null if invalid.
   */
  extractDateOfBirth(idNumber: string | undefined): string | undefined {
    if (!idNumber) {
      return undefined;
    }

    // Extract the birth date parts from the ID number
    const yearPrefix = this.getCenturyPrefix(idNumber.substring(0, 2));
    const year = idNumber.substring(0, 2);
    const month = idNumber.substring(2, 4);
    const day = idNumber.substring(4, 6);

    // Construct the birth date in 'CCYYMMDD' format
    return `${yearPrefix}${year}${month}${day}`;
  }

  /**
   * Determines the century prefix based on the ID number year digits.
   * Assumes ID numbers starting with '00' to '20' belong to the 2000s and '21' to '99' to the 1900s.
   * @param {string} yearDigits - The first two digits of the ID number year.
   * @return {string} - The century prefix ('19' or '20').
   */
  private getCenturyPrefix(yearDigits: string): string {
    const currentYearLastTwoDigits = new Date().getFullYear() % 100;
    return parseInt(yearDigits, 10) <= currentYearLastTwoDigits ? '20' : '19';
  }

  /**
   * Calculates the difference in months between two dates, considering the day of the month.
   * Both dates can be either a Date object or a Firestore Timestamp.
   * If the end date's day is before the start date's day, it does not count as a full month.
   * @param {Date | Timestamp} startDate - The start date.
   * @param {Date | Timestamp} endDate - The end date.
   * @return {number} - The corrected difference in months between the start and end dates.
   */
  getMonthsDifference(
    startDate: Date | Timestamp,
    endDate: Date | Timestamp
  ): number {
    const start =
      startDate instanceof Timestamp
        ? this.timestampToDate(startDate)
        : startDate;
    const end =
      endDate instanceof Timestamp ? this.timestampToDate(endDate) : endDate;

    // Calculate the initial difference in months
    const yearsDifference =
      (end?.getFullYear() || 0) - (start?.getFullYear() || 0);
    let monthsDifference = (end?.getMonth() || 0) - (start?.getMonth() || 0);
    const totalMonthsDifference = yearsDifference * 12 + monthsDifference;

    // Adjust if the day of the end date is before the day of the start date
    if (end && start && end.getDate() < start.getDate()) {
      monthsDifference -= 1;
    }

    return totalMonthsDifference + (monthsDifference < 0 ? -1 : 0);
  }

  /**
   * Gets the earliest date for the selected deduction day.
   * @param {number} selectedDeductionDay - The selected deduction day.
   * @return {Date | undefined} The earliest date for the selected deduction day if valid, otherwise undefined.
   */
  getTargetDay(selectedDeductionDay: number): Date | undefined {
    const today = new Date();
    const targetDate = this.calculateNextTargetDate(
      today,
      selectedDeductionDay
    );
    return targetDate;
  }

  /**
   * Calculates the next target date for the given deduction day.
   * @param {Date} currentDate - The current date.
   * @param {number} selectedDeductionDay - The selected deduction day.
   * @return {Date | undefined} The next target date if the function should run, otherwise undefined.
   */
  calculateNextTargetDate(
    currentDate: Date,
    selectedDeductionDay: number
  ): Date | undefined {
    const holidays = new Holidays('ZA');

    let targetDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      selectedDeductionDay
    );

    // Loop to find the next valid target date with at least 3 business days before it
    while (true) {
      if (targetDate < currentDate) {
        targetDate.setMonth(targetDate.getMonth() + 1);
      }

      const businessDaysDifference = this.calculateBusinessDaysDifference(
        currentDate,
        targetDate,
        holidays
      );
      if (businessDaysDifference > 3) {
        return targetDate;
      }

      // Move to the next month's deduction day if the current target date doesn't satisfy the condition
      targetDate.setMonth(targetDate.getMonth() + 1);
    }
  }

  /**
   * Calculates the number of business days between two dates,
   * considering weekends and public holidays.
   * @param {Date} startDate - The start date.
   * @param {Date} endDate - The end date.
   * @param {Holidays} holidays - The Holidays instance for checking public holidays.
   * @return {number} The number of business days between the start date and end date.
   */
  calculateBusinessDaysDifference(
    startDate: Date,
    endDate: Date,
    holidays: Holidays
  ): number {
    let businessDays = 0;
    const currentDate = new Date(startDate);
    while (currentDate < endDate) {
      if (this.isBusinessDay(currentDate, holidays)) {
        businessDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return businessDays;
  }

  /**
   * Checks if a date is a business day, considering weekends and public holidays.
   * @param {Date} date - The date to check.
   * @param {Holidays} holidays - The Holidays instance for checking public holidays.
   * @return {boolean} True if the date is a business day, otherwise false.
   */
  isBusinessDay(date: Date, holidays: Holidays): boolean {
    const dayOfWeek = date.getDay();
    const holidayResult = holidays.isHoliday(date);
    const isPublicHoliday =
      Array.isArray(holidayResult) && holidayResult[0]?.type === 'public';

    return dayOfWeek !== 0 && dayOfWeek !== 6 && !isPublicHoliday;
  }
}
