import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
// import algoliasearch from 'algoliasearch';
import { SnackBarService } from './snack-bar.service';
import { MemberPolicySearch } from '../models/search.model';
import { FilterService } from './filter.service';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';

export type SearchText = {
  offlineTransactions: string;
  policies: string;
  users: string;
  transactions: string;
  moveTransaction: string;
};

export type SearchResults = {
  offlineTransactions: number | undefined;
  policies: number | undefined;
  users: number | undefined;
  transactions: number | undefined;
  moveTransaction: number | undefined;
};
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  index: any;
  policyIndex: any;
  usersIndex: any;
  transactionIndex: any;
  logIndex: any;

  searchText: SearchText = {
    offlineTransactions: '',
    policies: '',
    users: '',
    transactions: '',
    moveTransaction: '',
  };

  searchResults: SearchResults = {
    offlineTransactions: undefined,
    policies: undefined,
    users: undefined,
    transactions: undefined,
    moveTransaction: undefined,
  };

  overrideMemberId: boolean = false;

  loadedPolicyMembers: MemberPolicySearch[] | undefined;
  filteredPolicyMembers: MemberPolicySearch[] | undefined;
  loadedOfflineTransactionPolicyMembers: MemberPolicySearch[] | undefined;
  filteredOfflineTransactionPolicyMembers: MemberPolicySearch[] | undefined;
  loadedUsers: User[] | undefined;
  filteredUsers: User[] | undefined;

  dataSourceSearchResults: MatTableDataSource<any> | null = null;
  dataSourceSearchPolicies: MatTableDataSource<MemberPolicySearch> | null =
    null;
  dataSourceSearchOfflineTransactionPolicies: MatTableDataSource<any> | null =
    null;

  dataSourceSearchMoveTransaction: MatTableDataSource<any> | null = null;
  dataSourceSearchUsers: MatTableDataSource<any> | null = null;
  dataSourceSearchTransactions: MatTableDataSource<any> | null = null;

  // constructor(private snackBarService: SnackBarService) {
  //   const client = algoliasearch(
  //     environment.algoliaAppId,
  //     environment.algoliaAPIKey
  //   );
  //   this.policyIndex = client.initIndex(environment.algoliaPolicyIndex);
  //   this.usersIndex = client.initIndex(environment.algoliaUsersIndex);
  //   this.transactionIndex = client.initIndex(
  //     environment.algoliaTransactionIndex
  //   );
  // }

  // async policySearch(query: string) {
  //   try {
  //     const res = await this.policyIndex.search(query);
  //     return res.hits;
  //   } catch (err) {
  //     if (err instanceof Error) this.snackBarService.latestError = err.message;
  //     this.snackBarService.openRedSnackBar('POLICY SEARCH FAILED!');
  //     return [];
  //   }
  // }

  // async usersSearch(query: string) {
  //   try {
  //     const res = await this.usersIndex.search(query);
  //     return res.hits;
  //   } catch (err) {
  //     if (err instanceof Error) this.snackBarService.latestError = err.message;
  //     this.snackBarService.openRedSnackBar('USER SEARCH FAILED!');
  //     return [];
  //   }
  // }

  public setSearchResultsDataSource(
    members: MemberPolicySearch[],
    searchText: string
  ) {
    this.dataSourceSearchResults = new MatTableDataSource(members);
    this.dataSourceSearchResults.filter = searchText.trim().toUpperCase();
  }

  updateCurrentSearchPolicies(members: MemberPolicySearch[]) {
    if (!this.dataSourceSearchPolicies) {
      this.dataSourceSearchPolicies =
        new MatTableDataSource<MemberPolicySearch>(members);
    } else {
      this.dataSourceSearchPolicies.data = members;
      this.dataSourceSearchPolicies._updateChangeSubscription();
    }
  }

  updateCurrentSearchOfflineTransactionPolicies(members: MemberPolicySearch[]) {
    if (!this.dataSourceSearchOfflineTransactionPolicies) {
      this.dataSourceSearchOfflineTransactionPolicies =
        new MatTableDataSource<MemberPolicySearch>(members);
    } else {
      this.dataSourceSearchOfflineTransactionPolicies.data = members;
      this.dataSourceSearchOfflineTransactionPolicies._updateChangeSubscription();
    }
  }

  updateCurrentSearchUsers(users: User[]) {
    if (!this.dataSourceSearchUsers) {
      this.dataSourceSearchUsers = new MatTableDataSource<User>(users);
    } else {
      this.dataSourceSearchUsers.data = users;
      this.dataSourceSearchUsers._updateChangeSubscription();
    }
  }
}
