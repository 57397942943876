import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AddOn, AddOnPlan, AddOnStatus } from '../models/addOn.model';
import { SnackBarService } from './snack-bar.service';
import { uuidv4 } from '@firebase/util';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject, catchError, map, takeUntil } from 'rxjs';
import { FilterService } from './filter.service';
import { PlanService } from './plan.service';
import { DateTimeService } from './date-time.service';
import { MainService } from './main.service';
import { Router } from '@angular/router';
import { Plan } from '../models/plan.model';

@Injectable({
  providedIn: 'root',
})
export class AddOnService {
  selectedAddOn: AddOn | undefined;
  selectedAddOnPlan: AddOnPlan | undefined;
  allAddOns: AddOn[] | undefined;

  selectedAddOnPlanIndex: number | undefined;

  dataSourceAddOns: MatTableDataSource<any> | undefined;
  dataSourceAddOnPlans: MatTableDataSource<any> | undefined;
  dataSourceAddOnPlanPremiums: MatTableDataSource<any> | undefined;

  selectedAddOn$: Observable<AddOn | undefined> | undefined;
  addOnDoc: AngularFirestoreDocument<AddOn> | undefined;

  selectedAddOnUpdated = new EventEmitter<void>();
  selectedPlansUpdated = new EventEmitter<void>();
  selectedAddOnPlanUpdated = new EventEmitter<void>();
  selectedPlanPremiumsUpdated = new EventEmitter<void>();

  @ViewChild(MatSort) sort: MatSort | undefined;

  destroy$ = new Subject<void>();

  // Form state
  loading = false;
  success = false;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private filterService: FilterService,
    public snackBarService: SnackBarService,
    private planService: PlanService,
    private dateTimeService: DateTimeService,
    private router: Router,
    private mainService: MainService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  // Load all add-ons from the 'addOn' collection in the database
  loadAddOns() {
    return new Promise<void>((resolve, reject) => {
      this.db
        .collection<AddOn>('addOn', (ref) => ref.orderBy('name'))
        .valueChanges({ idField: 'id' })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (addOns) => {
            this.dataSourceAddOns = new MatTableDataSource(addOns);
            this.dataSourceAddOns.sort = this.sort || null;
            this.allAddOns = addOns;
            this.filterService.filterClicked.addOns = false;
            this.filterService.toggleInactiveFilter(
              'addOns',
              this.dataSourceAddOns
            );
            resolve();
          },
          error: (err) => {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('LOADING ADD-ONS FAILED!');
            reject(err);
          },
        });
    });
  }

  // Get the selected add-on
  async setSelectedAddOn(addOnId?: string): Promise<void> {
    try {
      if (!addOnId) {
        this.router.navigate(['/products']);
        throw new Error('ADD-ON ID NOT PROVIDED!');
      }

      sessionStorage.setItem('selectedAddOnId', addOnId);

      this.addOnDoc = this.db.collection<AddOn>('addOn').doc(addOnId);

      this.selectedAddOn$ = this.addOnDoc.snapshotChanges().pipe(
        map((action) => {
          if (action.payload.exists) {
            return {
              id: action.payload.id,
              ...(action.payload.data() as AddOn),
            };
          } else {
            this.snackBarService.openRedSnackBar('ADD-ON DOCUMENT NOT FOUND!');
            throw new Error('ADD-ON DOCUMENT NOT FOUND!');
          }
        }),
        catchError((err) => {
          this.handleError(err);
          throw err;
        })
      );

      await new Promise<void>((resolve, reject) => {
        this.selectedAddOn$?.pipe(takeUntil(this.destroy$)).subscribe({
          next: (addOn) => {
            const storedAddOnId = sessionStorage.getItem('selectedAddOnId');
            if (addOn && addOn.id === storedAddOnId) {
              this.selectedAddOn = addOn;
              this.refreshAddOnPlans();
              this.refreshSelectedAddOnPlan();
              this.selectedAddOnUpdated.emit();
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.handleError(err);
        throw err;
      }
    }
  }

  private handleError(err: Error) {
    if (err.message) this.snackBarService.latestError = err.message;

    this.mainService.setLoading(false);
    this.snackBarService.openRedSnackBar('SETTING THE SELECTED ADD-ON FAILED!');
    this.router.navigate(['/products']);
  }

  // Set the array of all add-ons
  setAllAddOns(addOns: AddOn[]) {
    this.allAddOns = addOns;
  }

  // finds the ID of an add-on by name
  getAddOnById(addOnId: string) {
    if (this.allAddOns) {
      return this.allAddOns.find((addOn) => addOn.id === addOnId);
    } else {
      return {};
    }
  }

  // finds the ID of an add-on by name
  getAddOnIdByName(addOnName: string) {
    return this.allAddOns?.find((addOn) => addOn.name === addOnName)?.id;
  }

  getCurrentAddOnPlanStandardPremiumById(
    planId: string,
    addOnId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const addOnPlanPremiumHistory = (
      this.selectedAddOn?.id === addOnId
        ? this.selectedAddOn.plans
        : this.getAddOnPlans(addOnId)
    )?.find((addOnPlan) => addOnPlan.planId === planId)?.premium;

    if (!addOnPlanPremiumHistory) return undefined;

    const sortedPremiums = addOnPlanPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const nowAtMidnight = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = nowAtMidnight;

    if (timestamp) {
      const providedTimestampAtMidnight = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestampAtMidnight <= nowAtMidnight) {
        adjustedTimestamp = providedTimestampAtMidnight;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.standardAmount : undefined;
  }

  getCurrentAddOnPlanWaitingPremiumById(
    planId: string,
    addOnId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const addOnPlanPremiumHistory = (
      this.selectedAddOn?.id === addOnId
        ? this.selectedAddOn.plans
        : this.getAddOnPlans(addOnId)
    )?.find((addOnPlan) => addOnPlan.planId === planId)?.premium;

    if (!addOnPlanPremiumHistory) return undefined;

    const sortedPremiums = addOnPlanPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const nowAtMidnight = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = nowAtMidnight;

    if (timestamp) {
      const providedTimestampAtMidnight = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestampAtMidnight <= nowAtMidnight) {
        adjustedTimestamp = providedTimestampAtMidnight;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.waitingAmount : undefined;
  }

  // Get the name of an add-on by its ID
  getAddOnNameById(addOnId: string) {
    return this.allAddOns?.find((addOn) => addOn.id === addOnId)?.name
      ? {
          text: this.allAddOns.find((addOn) => addOn.id === addOnId)?.name,
          class: [''],
        }
      : { text: '(NOT FOUND)', class: ['italic'] };
  }

  // Returns an array of addOn plans for the addOn with the given ID
  getAddOnPlans(addOnId: string) {
    return this.allAddOns?.find((addOn) => addOn.id === addOnId)?.plans;
  }

  // Get the AddOn based on the add-on ID received
  getAddOnPlanById(planId: string, addOnId: string) {
    const addOn = this.allAddOns?.find((addOn) => addOn.id === addOnId);
    return addOn?.plans?.find((addOnPlan) => addOnPlan.planId === planId);
  }

  addOnHasSelectedPlanId(addOn: AddOn, planId: string): boolean {
    return (
      addOn.status !== 'INACTIVE' &&
      (addOn.plans?.some(
        (addOnPlan) =>
          addOnPlan.planId === planId && addOnPlan.status !== 'INACTIVE'
      ) ??
        false)
    );
  }

  getAllowedAddOnPlans(currentAddOnPlanId?: string) {
    const plans = this.planService.allPlans;

    return plans?.filter((plan) =>
      this.shouldIncludePlan(plan, currentAddOnPlanId)
    );
  }

  async getAllowedAddOnsForPlan(planId: string): Promise<AddOn[]> {
    try {
      // Assuming `this.addOnService.getAllAddOns` is a method that fetches all add-ons
      if (!this.allAddOns) {
        await this.loadAddOns();
      }
      const allAddOns: AddOn[] = this.allAddOns ?? [];

      // Filter the add-ons based on the status and the planId
      const allowedAddOns = allAddOns.filter(
        (addOn) =>
          addOn.status === AddOnStatus.ACTIVE &&
          addOn.plans?.some((plan) => plan.planId === planId)
      );

      return allowedAddOns;
    } catch (error) {
      console.error('Failed to fetch allowed add-ons for plan', error);
      return [];
    }
  }

  shouldIncludePlan(plan: Plan, currentAddOnPlanId?: string) {
    const isPlanNotAdded = !this.selectedAddOn?.plans?.some(
      (addOnPlan) => addOnPlan.planId === plan.id
    );

    const hasNoActiveVersion =
      this.selectedAddOn?.plans?.some(
        (addOnPlan) => addOnPlan.planId === plan.id
      ) &&
      !this.selectedAddOn?.plans?.some(
        (addOnPlan) =>
          addOnPlan.planId === plan.id && addOnPlan.status === 'ACTIVE'
      );

    const isCurrentAddonPlan = plan.id === currentAddOnPlanId;

    return (
      isPlanNotAdded ||
      (hasNoActiveVersion && plan.status !== 'INACTIVE') ||
      isCurrentAddonPlan
    );
  }

  public refreshAddOnPlans() {
    this.dataSourceAddOnPlans = new MatTableDataSource(
      this.selectedAddOn?.plans
    );
    this.filterService.filterClicked.addOnPlans = false;
    this.filterService.toggleInactiveFilter(
      'addOnPlans',
      this.dataSourceAddOnPlans
    );
  }

  public refreshSelectedAddOnPlan() {
    if (
      this.selectedAddOn?.plans &&
      this.selectedAddOnPlanIndex &&
      this.selectedAddOnPlanIndex >= 0 &&
      this.selectedAddOn?.plans[this.selectedAddOnPlanIndex]
    ) {
      this.selectedAddOnPlan =
        this.selectedAddOn.plans[this.selectedAddOnPlanIndex];
      this.dataSourceAddOnPlanPremiums = new MatTableDataSource(
        this.selectedAddOnPlan?.premium
      );
      this.selectedAddOnPlanUpdated.emit();
    }
  }

  resetSelectedAddOn() {
    this.selectedAddOn = undefined;
    this.dataSourceAddOnPlans = new MatTableDataSource<any>([]);
  }

  cleanUp() {
    this.destroy$.next();
  }
}
