export const environment = {
  production: false,
  url: 'https://dev.ionbase.co.za/',
  title: 'IonBase Dev 🛠️',
  homeTitle: '🛠️ DEVELOP',
  name: 'Dev',
  sentryDSN:
    'https://4395cd81cdbc4ad0aee1479ccf7a019b@o4505509938593792.ingest.sentry.io/4505509999869952',
  algoliaPolicyIndex: 'ionDev-policy',
  algoliaUsersIndex: 'ionDev-users',
  algoliaTransactionIndex: 'ionDev-transaction',
  algoliaAPIKey: 'd9d40281b2508fc121c26e4a40a566c2',
  algoliaAppId: 'I6WJK51G1P',
  smsPortalKey: '281a59cb-7632-47d7-b88e-52f916d2f27a',
  smsPortalSecret: 'QJdXe5G+QNFxbN2WAbMobhzFJwlaJx6C',
  validateUserURL:
    'https://europe-west3-ionbasedev.cloudfunctions.net/validateUser',
  updateUID_URL: 'https://europe-west3-ionbasedev.cloudfunctions.net/updateUID',
  newPortalPolicyURL:
    'https://europe-west3-ionbasedev.cloudfunctions.net/newPortalPolicy',
  newPortalMemberURL:
    'https://europe-west3-ionbasedev.cloudfunctions.net/newPortalMember',
  validPortalUser:
    '7cf24bf062fa1a8f4a23e05742430c00346b338a08ecfff1fc1cbb2b8c7a106b',
  isIdNumberDuplicateURL:
    'https://europe-west3-ionbasedev.cloudfunctions.net/isIdNumberDuplicate',
  firebase: {
    apiKey: 'AIzaSyBXhh87y2kWVqMZ91wSQOD0AgyHAoTh0bI',
    authDomain: 'ionbasedev.firebaseapp.com',
    projectId: 'ionbasedev',
    storageBucket: 'ionbasedev.appspot.com',
    messagingSenderId: '1000028336722',
    appId: '1:1000028336722:web:e7ffa36e9fdb969bb1e58b',
    measurementId: 'G-HRWSNX969B',
  },
};
